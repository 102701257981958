.events {

   &-root {
     max-width: 100%;
   }

  &-content {
    width: 952px;
  }

  &-title {
    // // background: linear-gradient(90deg, rgba(151,87,150,1) 0%, rgba(254,87,205,1) 50%, rgba(151,87,150,1) 100%);
    background: #ed6b0b;
    width: 95%;
    height: 32px;
    margin: 6px auto 8px;
  }

  &-titleText {
    width: 96%;
    font-size: 20px;
  }

  &-item {
    margin: 16px 0;
    width: 100%;
  }

  &-left {
    width: 12%;
  }

  &-avatar {
    width: 110px;
    height: 125px;
    border-radius: 10px;
  }

  &-avatarWrapper {
    margin: 0 16px 0 4px;
  }

  &-right {
    width: 77%;
    margin-left: 16px;
  }

  &-advText {
    font-size: 33px;
    cursor: pointer;
    margin-bottom: 24px;
  }

  &-date {
    font-size: 16px;
  }

  &-scrollButtonHover {
    display: none;
  }

  &-scrollWrapper {
    width: 100%;
    margin-bottom: 8px;
  }

  &-scroll {
    background: none;
    border: none;

    &:focus {
      outline: none;
    }

    &:hover {
      .events-scrollButton {
        display: none;
      }

      .events-scrollButtonHover {
        display: block;
      }
    }
  }

  &-itemsWrapper {
    margin-bottom: 48px;
    height: 576px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  &-close {
    background: none;
    border: none;
    &:hover {
      .events-closeIcon {
        display: none;
      }

      .events-closeIconHov {
        display: block;
      }
    }
  }

  &-closeIconHov {
    display: none;
  }

}

@media(max-width: $large-media) {
  .events {

    &-content {
      width: 98%;
    }

    &-title {
      margin: 0.5vw auto;
    }

    &-titleText {
      font-size: 1.8vw;
    }

    &-closeIcon {
      width: 2.2vw;
    }

    &-itemsWrapper {
      margin-bottom: 4.2vw;
      height: 50.5vw;
    }

    &-avatarWrapper {
      margin: 0 1.4vw 0 0.4vw;
    }

    &-item {
      margin: 1.4vw 0;
    }

    &-right {
      width: 77%;
      margin-left: 1.4vw;
    }

    &-advText {
      font-size: 2.9vw;
      margin-bottom: 2.1vw;
    }

    &-date {
      font-size: 1.4vw;
    }

    &-avatar {
      width: 10vw;
      height: 11vw;
    }

    &-scrollButton, &-scrollButtonHover {
      width: 4.7vw;
      height: 4.7vw;
    }
  }
}

@media(max-width: $medium-media) {
  .events {

    &-content {
      width: 75%;
    }

    &-title {
      background: #5a91e2;
      width: 98%;
      height: 5vw;
      margin-bottom: 2.9vw;
    }

    &-titleText {
      font-size: 3vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 4vw;
      height: 4vw;
    }

    &-avatar {
      width: 15.4vw;
      height: 16.7vw;
    }

    &-left {
      width: 21%;
    }

    &-advText {
      font-size: 4vw;
      margin-bottom: 0.4vw;
    }

    &-date {
      font-size: 2vw;
    }

    &-item {
      margin: 0 0 6vw;
    }

    &-itemsWrapper {
      padding-left: 2.3vw;
      height: 87.5vh;
    }

    &-scrollButton, &-scrollButtonHover {
      width: 10.7vw;
      height: 10.7vw;
    }

    &-help {
      width: 64%;
      font-size: 3.8vw;
    }

    &-buttonWrapper {
      margin-bottom: 2.3vw;
    }

    &-right {
      margin-left: 3vw;
    }


  }
}

@media(max-width: $small-media) {
  .events {
    &-content {
      width: 100%;
    }

    &-title {
      height: 8vw;
    }

    &-titleText {
      font-size: 5vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 5vw;
      height: 5vw;
    }

    &-date {
      font-size: 2.5vw;
    }

    &-itemsWrapper {
      height: 51vh;
    }
  }
}