@use 'sass:color';

.form-check .form-check-input {
  margin-left: 0;
}

.form-check {
  padding-left: 0;
}

.form-check-input:focus {
  background: $checkboxBgColor;
  outline: 0;
  border-color: $checkboxBorderColor;
  box-shadow: inset 3px 3px 3px 0 color.change($fieldBgColor, $alpha: 0.8);
}

.form-check-input:active {
  filter: brightness(100%);
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml, #{$iconCheckbox}");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml, #{$iconCheckbox}");
}

.form-check-input:checked {
  // box-shadow: inset 3px 3px 3px 0 rgba(160,112,156,.8);
  box-shadow: inset 3px 3px 3px 0 $checkboxCheckedColor;
  background-color: $fieldBgColor;
}

.form-check-input {
  width: 1.5em;
  height: 1.5em;
  margin-top: 0;
  box-shadow: inset 3px 3px 3px 0 color.change($fieldBgColor, $alpha: 0.8);
  border: none;
  background: $fieldBgColor;
}

.modal-backdrop.show {
  opacity: 0;
}

.modal-dialog {
  margin-top: 21.6vh;
}

.modal-content {
  @extend %baseBoxShadow;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  border-radius: 7px;
  border: none;
  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: -7px;
  //   bottom: -7px;
  //   left: -7px;
  //   right: -7px;
  //   background: linear-gradient(0deg, rgba(157, 100, 24, 1) 0%, rgba(243, 236, 114, 1) 46%, rgba(220, 176, 94, 1) 100%);
  //   border-radius: 10px;
  //   z-index: -1;
  // }
}

.dropdown-menu {
  min-width: 16px;
}

.bootstrap-tagsinput {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  padding: 4px 6px;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  max-width: 100%;
  line-height: 22px;
  cursor: text;
}

.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: 192px;
}

.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #777;
  opacity: 1;
}

.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  color: #777;
}

.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: #777;
}

.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}

.bootstrap-tagsinput .tag {
  display: block;
  background: $white;
  margin: 2px;
  border-radius: 10px;
  padding: 4px 8px;
  border: 2px solid #ae60b8;
  box-shadow: inset 3px 3px 3px 0 rgba(160, 112, 156, 0.8);
}

.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "✕";
}

.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  //box-shadow: inset 0 1px 3px 0 rgba(255, 255, 255, 0.8), 0 1px 2px rgba(0, 0, 0, 0.05);
  color: #f88d8d;
}

.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

@media(max-width: $medium-media) {
  .bootstrap-tagsinput input {
    max-width: unset;
  }
  .modal-dialog {
    margin-top: 2vw;
  }
}