.kmdl {
  &-avatar {
    width: 62px;
  }

  &-root {
    max-width: 100%;
  }

  &-content {
    width: 952px;
  }

  &-title {
    // background: linear-gradient(90deg, rgba(151,87,150,1) 0%, rgba(254,87,205,1) 50%, rgba(151,87,150,1) 100%);
    background: $popupTitleBgColor;
    width: 95%;
    height: 32px;
    margin: 6px auto;
  }

  &-titleText {
    width: 96%;
    font-size: 20px;
  }

  &-scrollButtonHover {
    display: none;
  }

  &-scrollWrapper {
    width: 100%;
  }

  &-scroll {
    background: none;
    border: none;

    &:focus {
      outline: none;
    }

    &:hover {
      .kmdl-scrollButton {
        display: none;
      }

      .kmdl-scrollButtonHover {
        display: block;
      }
    }
  }

  &-close {
    background: none;
    border: none;

    &:hover {
      .kmdl-closeIcon {
        display: none;
      }

      .kmdl-closeIconHov {
        display: block;
      }
    }
  }
  &-closeIconHov {
    display: none;
  }
}

@media(max-width: $large-media) {
  .kmdl {

    &-title {
      margin: 0.5vw auto;
    }

    &-titleText {
      font-size: 1.8vw;
    }

    &-closeIcon {
      width: 2.2vw;
    }
  }
}

@media(max-width: $medium-media) {
  .kmdl {

    &-content {
      width: 75%;
    }

    &-title {
      height: 5vw;
    }

    &-titleText {
      font-size: 3vw;
    }

    &-closeIcon,
    &-closeIconHov {
      width: 4vw;
      height: 4vw;
    }

    &-footer {
      margin: 1vw 0 3.1vw;
      flex-direction: column;
      align-items: center;
    }
  }
}

@media(max-width: $small-media) {
  .kmdl {
    &-content {
      width: 100%;
    }

    &-title {
      height: 8vw;
    }

    &-titleText {
      font-size: 5vw;
    }

    &-closeIcon,
    &-closeIconHov {
      width: 5vw;
      height: 5vw;
    }
  }
}
