.find {

  &-root {
    max-width: 100%;
  }

  &-main {
    padding-top: 1.4vw;
  }

  &-content{
    width: 1368px;
  }

  &-title {
    // background: linear-gradient(90deg, rgba(151,87,150,1) 0%, rgba(254,87,205,1) 50%, rgba(151,87,150,1) 100%);
    background: $popupTitleBgColor;
    width: 95%;
    height: 32px;
    margin: 6px auto;
  }

  &-titleText {
    width: 96%;
    font-size: 20px;
  }

  &-closeIconHov {
    display: none;
  }

  &-close {
    background: none;
    border: none;
    &:hover {
      .find-closeIcon {
        display: none;
      }

      .find-closeIconHov {
        display: block;
      }
    }
  }

  &-settings {
    margin-bottom: 48px;
    background: none;
    border: none;
    &:hover {
      .find-settingsIcon {
        display: none;
      }

      .find-settingsIconHover {
        display: block;
      }
    }
  }

  &-container {
    flex-wrap: wrap;
  }

  &-settingsIconHover {
    display: none;
  }

  &-itemsWrapper {
    width: 98%;
    flex-wrap: wrap;
    height: 584px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  &-item {
    @extend %baseBoxShadow;
    background: none;
    // border: 3px solid #dbc758;
    border: none;
    border-radius: 10px;
    padding: 16px 23px 10px 16px;
    margin: 2px 5px;
    width: 14rem;
    overflow-x: hidden;
    // max-height: 50%;  // выключаем "растяжку" элемента по высоте если элементов в контейнере хватает только на одну строку
    img {
      max-height: 207px;
      max-width: 184px;
      object-fit: contain;
    }
  }

  &-noPhoto {
    margin-bottom: 8px;
  }

  &-about {
    width: 88%;
  }

  &-online {
    width: 29px;
    height: 29px;
    background: #22a428;
    box-shadow: inset 3px 3px 3px 0px rgba(0,0,0,0.3);
    border-radius: 50%;
  }

  &-offline {
    width: 29px;
    height: 29px;
    // #22a428;
    background: linear-gradient(90deg, rgb(185, 187, 185) 0%, rgb(90, 90, 90) 100%);
    box-shadow: inset 3px 3px 3px 0px rgba(0,0,0,0.3);
    border-radius: 50%;
  }

  &-info {
    margin-right: 8px;
  }

  &-name, &-info {
    font-size: 20px;
    white-space: normal;
  }

  &-infoSmall {
    font-size: 12px;
  }

  &-scrollButtonHover--up, &-scrollButton--up {
    transform: rotate(180deg);
  }

  &-scrollButtonHover {
    display: none;
  }


  &-scroll {
    margin-top: 8px;
    background: none;
    border: none;

    &:hover {
      .find-scrollButton {
        display: none;
      }

      .find-scrollButtonHover {
        display: block;
      }
    }
  }

  &-footer {
    margin-top: 8px;
    width: 100%;
    margin-bottom: 16px;
  }

  &-goldLine {
    margin-bottom: 16px;
    height: 3px;
    width: 90%;
    background: #dbc758;
  }

   &-help {
     width: 20%;
     font-size: 24px;
  }

  &-bottom {
    width: 100%;
  }

  &-logo {
    margin-left: 16px;
  }

}

@media(max-width: $extraLarge-media) {
  .find {
    &-content {
      width: 90%;
    }
  }
}

@media(max-width: $large-media) {
  .find {

    &-footer {
      margin-top: 2.8vw;
      margin-bottom: 1.4vw;
    }

    &-topUpButton {
      font-size: 2.4vw;
      margin-bottom: 2.1vw;
    }

    &-goldLine {
      margin-bottom: 1.4vw;
    }

    &-help {
      font-size: 2vw;
    }

    &-logo {
      margin-left: 1.4vw;
      width: 28%;
    }

    &-button {
      width: 23%;
    }

  }
}

@media(max-width: $medium-media) {
  .find {
    &-content {
      width: 98%;
    }

    &-title {
      // background: linear-gradient(90deg, rgba(151,87,150,1) 0%, rgba(254,87,205,1) 50%, rgba(151,87,150,1) 100%);
    background: #ed6b0b;
      justify-content: flex-end;
      height: 8vw;
    }

    &-titleText {
      font-size: 3vw;
    }

    &-item {
      width: 43%;
      height: 47vw;
      border: 3px solid #dbc758;
      padding: 2.1vw 3vw 1.3vw 2.1vw;
      margin: 1vw 3vw;
    }

    &-left {
      width: 87%;
    }

    &-itemsWrapper {
      width: 90%;
      height: 147vw;
    }

    &-online {
      width: 4.4vw;
      height: 4.4vw;
      margin-bottom: 3vw;
    }

    &-info, &-name {
      font-size: 3vw;
    }

    &-infoSmall {
      font-size: 2.5vw;
    }

    &-noPhoto {
      margin-bottom: 1vw;
      width: 100%;
      height: 34.5vw;
    }

    &-settings {
      margin-top: -4vw;
      margin-bottom: 0;
    }

    &-settings {
      margin-bottom: 0;
      margin-top: -8vw;
    }

    &-settingsIcon, &-settingsIconHover {
      width: 10vw;
      height: 10vw;
    }

    &-help {
      width: 63%;
      font-size: 5vw;
    }

    &-footer {
      margin-top: 2vw;
      margin-bottom: 6vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 5vw;
      height: 5vw;
    }

    &-scroll {
      z-index: 2;
      margin-top: -16vw;
      margin-bottom: 5vw;
    }

    &-scrollButton, &-scrollButtonHover {
      width: 10vw;
      height: 10vw;
    }

  }
}

@media(max-width: $small-media) {
  .find {
    &-itemsWrapper {
      width: 98%;
    }
  }
}

