#footer-index {
  position: absolute;
  bottom: 0;
  right: 0; left: 0;
}

.footer {
  &-pinkLine {
    // background-color: #ffedfa;
    // background-color: transparent;
    height: 0.5vw;
  }
  &-refs{
    // background-color: $white;
    // height: 2vw;
  }
  &-href{
    display: inline-block;
    text-decoration: none;
    color: $black;
    margin: 0.4vw 1vw;
    font-size: 0.8vw;
  }
  &-payment-logos{
    height: 2vw;
  }
}

@media(max-width: $extraLarge-media) {
  .footer {
    &-refs{
      min-height: 23px;
      background-position: center;
    }
    &-href{
      font-size: 14px;
      margin: 10px 5px;
    }
    &-pinkLine{
      height: 8px;
    }
  }
}

@media(max-width: $medium-media) {
  .footer {
    &-href {
      font-size: 12px;
      margin: 10px 5px;
    }
  }
}

@media(max-width: $small-media) {
  .footer {
    &-copyright{
      order: 4;
    }
    &-nav{
      flex-wrap: wrap;
    }
    &-href{
      margin: 10px 10px;
    }
  }
}

