.messenger {
  //  &-avatar {
  //   width: 62px;
  //   height: 62px;
  //   object-fit: cover;
  //  }

   &-avatar {
    width: 51px;
    height: 59px;
    border-radius: 10px;
    // object-fit: cover;
    object-fit: contain;
  }

   &-root {
     max-width: 100%;
   }

  &-content {
    width: 952px;
  }

  &-title {
    // background: linear-gradient(90deg, rgba(151,87,150,1) 0%, rgba(254,87,205,1) 50%, rgba(151,87,150,1) 100%);
    background: $popupTitleBgColor;
    width: 95%;
    height: 32px;
    margin: 6px auto;
  }

  &-titleText {
    width: 96%;
    font-size: 20px;
  }

  &-genderFemale {
    margin-right: 6px;
  }

  &-item {
    margin: 4px 0;
    width: 100%;
  }

  &-left {
    padding: 4px 0 4px 4px;
    // width: 30%;
    width: 21%;
    // border: 2px solid #f656a0;
    // border-radius: 10px;
  }

  &-avatarWrapper {
    margin: 0 16px 0 4px;
  }

  &-info {
    font-size: 13px;
  }

  &-right {
    // width: 50%;
    width: 58%;
    margin-left: 16px;
  }

  &-advText {
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    cursor: pointer;
    color: $dialogNameColor;
  }

  &-date {
    font-size: 16px;
  }

  &-scrollButtonHover {
    display: none;
  }

  &-scrollWrapper {
    width: 100%;
  }

  &-scroll {
    background: none;
    border: none;

    &:focus {
      outline: none;
    }

    &:hover {
      .announcement-scrollButton {
        display: none;
      }

      .announcement-scrollButtonHover {
        display: block;
      }
    }
  }

  &-textarea {
    resize: none;
    margin-right: 16px;
    width: 58%;
    border: 2px solid #dcb05e;
    border-radius: 10px;
    box-shadow: inset 1px 1px 5px -1px rgba(0,0,0,0.49);
    padding: 4px 8px;
    &::placeholder {
      color: #cccccc;
    }
  }

  &-button {
    font-size: 21px;
    width: 30%;
    border: 2px solid #dbc758;
    &:hover {
      color: #000;
      background: #7dbae4;
      box-shadow: none;
    }
  }

  &-itemsWrapper {
    margin-bottom: 16px;
    height: 599px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  &-footer {
    margin: 8px 0 24px 0;
    width: 100%;
  }

  &-close {
    background: none;
    border: none;
    &:hover {
      .announcement-closeIcon {
        display: none;
      }

      .announcement-closeIconHov {
        display: block;
      }
    }
  }

  &-closeIconHov {
    display: none;
  }

}

@media(max-width: $large-media) {
  .announcement {

    &-title {
      margin: 0.5vw auto;
    }

    &-titleText {
      font-size: 1.8vw;
    }

    &-closeIcon {
      width: 2.2vw;
    }

    &-avatarWrapper {
      margin: 0 1.4vw 0 0.4vw;
    }

    &-genderFemale {
      margin-right: 0.7vw;
    }

    &-avatar {
      width: 5.2vw;
    }

    &-genderMale {
      width: 4vw;
    }

    &-genderFemale {
      width: 3.4vw;
    }

    &-info {
      font-size: 1.2vw;
    }

    &-advText {
      font-size: 1.6vw;
    }

    &-date {
      font-size: 1.6vw;
    }

    &-left {
      padding: 0.4vw 0 0.4vw 0.4vw;
    }

    &-item {
      margin: 0.4vw 0;
    }

    &-right {
      margin-left: 1.4vw;
    }

  }
}

@media(max-width: $medium-media) {
  .announcement {

    &-content {
      width: 75%;
    }

    &-title {
      height: 5vw;
    }

    &-titleText {
      font-size: 3vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 4vw;
      height: 4vw;
    }

    &-item {
      flex-direction: column;
      align-items: flex-start;
    }

    &-genderFemale {
      margin-right: 0px;
    }

    &-left {
      width: 76%;
    }

    &-right {
      width: 92%;
      margin-left: 0;
    }

    &-avatar {
      width: 12vw;
    }

    &-genderFemale {
      width: 7.4vw;
    }

    &-genderMale {
      width: 7.5vw;
    }

    &-info {
      font-size: 2.6vw;
    }

    &-advText, &-date {
      font-size: 3.6vw;
    }

    &-avatarWrapper {
      margin: 0 3.3vw 0 2.6vw;
    }

    &-item {
      margin: 3vw auto 3vw;
      padding-left: 4vw;
    }

    &-itemsWrapper {
      margin-bottom: 2vw;
      height: 58.3vw;
    }

    &-ref {
      order: 2;
    }

    &-scrollButton, &-scrollButtonHover {
      width: 11vw;
    }

    &-footer {
      margin: 1vw 0 3.1vw;
      flex-direction: column;
      align-items: center;
    }

    &-textarea {
      height: 26vw;
      width: 84%;
      margin-right: 0;
      font-size: 3vw;
      margin-bottom: 4vw;
    }

    &-button {
      font-size: 3vw;
      padding: 0.8vw 0;
      width: 66%;
      margin-bottom: 2vw;
    }

    &-help {
      font-size: 3vw;
      padding: 0.8vw 0;
      width: 66%;
    }

  }
}

@media(max-width: $small-media) {
  .announcement {
    &-content {
      width: 100%;
    }

    &-title {
      height: 8vw;
    }

    &-titleText {
      font-size: 5vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 5vw;
      height: 5vw;
    }

    &-button {
      font-size: 4vw;
    }

    &-help {
      font-size: 4vw;
    }

    &-itemsWrapper {
      height: 50vh;
    }
  }
}