@use 'sass:color';

$gradSelectOptionBg: linear-gradient($fieldBorderColor, $fieldBorderColor);
$gradSelectScrollBg: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 102, 210, 1) 2%,
    rgba(160, 102, 160, 1) 50%,
    rgba(255, 102, 210, 1) 98%,
    rgba(255, 102, 210, 0) 100%
);
$gradSelectScrollThumbBg: linear-gradient(
    0deg,
    rgba(255, 102, 210, 0.7) 0%,
    rgba(160, 102, 160, 0.7) 50%,
    rgba(255, 102, 210, 0.7) 100%
);