.registerModal {
    z-index: 1051;

    &-root {
        max-width: 100%;
    }

    &-status {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
        white-space: pre-wrap;
        text-align: center;
        margin-top: 16px;
    }

    &-lateralSize {
        width: 27%;
    }

    &-centralSize {
        width: 46%;
    }

    &-inputPosition {
        display: flex;
    }

    &-content {
        width: 672px;
    }

    &-wrapper {
        width: 100%;
        margin-top: 10px;
    }

    &-registerBar {
        margin: 5px auto;
        color: $white;
        height: 24px;
        // background: linear-gradient(
        //     90deg,
        //     rgba(160, 102, 160, 1) 0%,
        //     rgba(254, 102, 209, 1) 50%,
        //     rgba(160, 102, 160, 1) 100%
        // );
        background: $popupTitleBgColor;
        width: 90%;
    }

    &-size {
        width: 288px;
        height: 36px;
    }

    &-sizeSelectChooseDate {
        // background: #fadaf4;
        background: $fieldBgColor;
        border-radius: 5px;
        width: 88px;
        height: 36px;
        margin: 0 auto;
    }

    &-labelText {
        margin-top: 8px;
        font-size: 14px;
        color: $orangeTextColor;
    }

    &-inputArea {
        // background: #fadaf4;
        background: $fieldBgColor;
        width: 288px;
        height: 32px;
        margin-top: 4px;
    }

    &-passwordInfo {
        font-size: 9px;
        line-height: 1;
    }

    &-brownLine {
        background: #dbc758;
        height: 1px;
        width: 90%;
        margin-top: 16px;
    }

    &-button {
        font-size: 16px;
        width: 275px;
        margin-left: 24px;
    }

    &-loginWithSocNet {
        width: 100px;
        color: $orangeTextColor;
        font-size: 12px;
    }

    &-hrefs {
        margin-left: 24px;
    }

    &-footer {
        margin-bottom: 20px;
    }

    &-ref:hover {
        transform: scale(0.8);
    }
}

@media (max-width: $medium-media) {
    .registerModal {
        &-inputArea {
            width: 39vw;
        }

        &-sizeSelectChooseDate {
            width: 13vw;
            &:focus {
                width: 13vw;
            }
        }
    }
}

@media (max-width: $small-media) {
    .registerModal {
        &-sizeSelectChooseDate {
            width: 20vw;
        }

        &-footer {
            flex-direction: column;
        }

        &-lateralSize {
            justify-content: center;
            width: 20%;
        }

        &-centralSize {
            width: 80%;
        }

        &-inputArea {
            width: 73vw;
        }

        &-size {
            width: 73vw;
        }

        &-sizeSelectChooseDate {
            width: 30vw;
            &:focus {
                width: 30vw;
            }
        }

        &-mobileFlexColumn {
            flex-direction: column;
        }

        &-centralSizeMobile {
            width: 98%;
            margin: 0 auto;
        }

        &-labelTextMobile {
            margin: 0;
            font-size: 14px;
            color: #f48400;
        }

        &-mobileFlexWrap {
            flex-wrap: wrap;
        }

        &-passwordInfoMobileSize {
            width: 100%;
            justify-content: flex-end;
        }

        &-passwordInfo {
            width: 75%;
        }

        &-passwordMobile {
            font-size: 10px;
            text-align: end;
        }

        &-withSocialNetwork {
            order: 1;
        }

        &-button {
            margin-left: 0;
            order: 0;
            margin-bottom: 24px;
        }

        &-brownLine {
            order: 2;
        }

        &-buttonVideoHelp {
            order: 3;
            margin-top: 10px;
            width: 275px;
        }
    }
}
