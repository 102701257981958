.warningBalance {
//   z-index: 1052;

  &-root {
    max-width: 100%;
  }

  &-content {
    &:before {
      background: none;
    }
    background: $warningBalanceBgColor;
    // width: 145px;
    // height: 235px;
    // border: 4px solid #ff0000;
    // border-radius: 0;

    width: 31.5vw;
    height: 39vw;
    // border: 4px solid #ff0000;
    // border-radius: 0;
  }

  &-coinIcon {
    width: 96px;
    opacity: 0.4;
  }

  &-screamer {
    margin-top: .7vw;
    // color: #ff0000;
    // font-size: 43px;
    // letter-spacing: 3px;
  }

  &-text {
    // font-size: 14px;
    margin-top: .7vw;
    color: $warningBalanceCommentColor;
  }

  &-balance {
    color: $warningBalanceCoinColor;
  }

  &-balanceCount {
    color: $warningBalanceCoinColor;
    font-size: 18px;
  }

  &-button {
    margin-top: 1.5vw;
    // font-size: 15px;
    // border-radius: 0;
  }

  &-picture {
    margin-top: 0.5vw;
  }

}
