// @import "vendor/index.scss";
@import "partials/colors.scss";
@import "partials/gradients.scss";
@import "partials/utils.scss";
@import "elements";
@import "partials/header.scss";
@import "partials/body.scss";
@import "partials/main.scss";
@import "partials/buttons.scss";
@import "partials/footer.scss";
@import "partials/forgotPassword-modal.scss";
@import "partials/auth-modal.scss";
@import "partials/newPassword-modal.scss";
@import "partials/register-modal.scss";
@import "partials/bootstrap-overwrite.scss";
@import "partials/slider.scss";
@import "partials/myProfile-header.scss";
@import "partials/myProfile-main.scss";
@import "partials/myProfile-footer.scss";
@import "partials/k-modal.scss";
@import "partials/profileInTopRegion-modal.scss";
@import "partials/upInSearch-modal.scss";
@import "partials/activateVip-modal.scss";
@import "partials/activateVipScreen2-modal.scss";
@import "partials/warningBalance-modal.scss";
// @import "partials/warningModerate-modal.scss";
@import "partials/profileInTopCountry-modal.scss";
@import "partials/inboxMessages-modal.scss";
@import "partials/topUpBalance-modal.scss";
@import "partials/aboutMe-modal.scss";
@import "partials/announcement-modal.scss";
@import "partials/gifts-modal.scss";
@import "partials/strangersProfile-main.scss";
@import "partials/events-modal.scss";
@import "partials/find-modal.scss";
@import "partials/findSettings-modal.scss";
@import "partials/dialog-modal.scss";
@import "partials/docs-modal.scss";
@import "partials/messenger-modal.scss";
@import "partials/overwrite.scss";


html, body{
    // font-size: 62.5%; // 10px
    min-height: 100%;
}

body{
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    flex-direction: column;
}

.input {
    // background: #fadaf4;
    background-color: $fieldBgColor;
    border-radius: 10px;
    border: none;
    // box-shadow: inset 3px 3px 3px 0px rgba(215, 187, 210, 0.73);
    box-shadow: inset 3px 3px 3px 0px $fieldBoxShadowColor;
    text-align: center;
    &:focus {
        outline: none;
    }
}

.owl-stage {
    display: flex;
}

button {
    outline: 0 !important;
}

.select {
    position: relative;
    border-radius: 7px;
    border: none;
    box-shadow: inset 3px 3px 3px 0px $fieldBoxShadowColor;
    &:focus {
        height: auto;
        position: absolute;
        outline: none;
        box-shadow: none;
        padding: 5px !important;
        color: #333 !important;
        background-color: $white !important;
        border: 1px solid $fieldBorderColor;
        overflow: auto;
        z-index: 1100;
        overflow-x: hidden !important;
    }
    & option:hover {
        background: $gradSelectOptionBg;
        color: $white;
        border-radius: 5px;
        padding: 0px 5px;
    }
    & option:checked {
        background: $gradSelectOptionBg;
        color: $white;
        border-radius: 5px;
        padding: 0px 5px;
        border: 1px solid $white;
    }
    &::-webkit-scrollbar {
        background: $gradSelectScrollBg;
        width: 13px;
        height: 20px !important;
        border-radius: 10px;
        border: 4px solid $white;
    }
    &::-webkit-scrollbar-thumb {
        background: $gradSelectScrollThumbBg;
        width: 15px;
        border-radius: 5px;
    }
}

.custom-scroll {
    border-radius: 7px;
    &::scrollbar {
        // background: linear-gradient(
        //     0deg,
        //     rgba(255, 255, 255, 0) 0%,
        //     rgba(255, 102, 210, 1) 2%,
        //     rgba(160, 102, 160, 1) 50%,
        //     rgba(255, 102, 210, 1) 98%,
        //     rgba(255, 102, 210, 0) 100%
        // );
        background: $gradSelectScrollBg;
        width: 13px;
        height: 20px !important;
        border-radius: 10px;
        border: 4px solid $white;
    }
    &::scrollbar-thumb {
        // background: linear-gradient(
        //     0deg,
        //     rgba(255, 102, 210, 0.7) 0%,
        //     rgba(160, 102, 160, 0.7) 50%,
        //     rgba(255, 102, 210, 0.7) 100%
        // );
        background: $gradSelectScrollThumbBg;
        width: 15px;
        border-radius: 5px;
    }

    &::-webkit-scrollbar {
        // background: linear-gradient(
        //     0deg,
        //     rgba(255, 255, 255, 0) 0%,
        //     rgba(255, 102, 210, 1) 2%,
        //     rgba(160, 102, 160, 1) 50%,
        //     rgba(255, 102, 210, 1) 98%,
        //     rgba(255, 102, 210, 0) 100%
        // );
        background: $gradSelectScrollBg;
        width: 13px;
        height: 20px !important;
        border-radius: 10px;
        border: 4px solid $white;
    }
    &::-webkit-scrollbar-thumb {
        // background: linear-gradient(
        //     0deg,
        //     rgba(255, 102, 210, 0.7) 0%,
        //     rgba(160, 102, 160, 0.7) 50%,
        //     rgba(255, 102, 210, 0.7) 100%
        // );
        background: $gradSelectScrollThumbBg;
        width: 15px;
        border-radius: 5px;
    }
}

button {
    &:focus {
        outline: none;
    }
}

input {
    &:focus {
        outline: none;
    }
}

textarea {
    &:focus {
        outline: none;
    }
}

.error {
    color: red;
}

.success {
    color: green;
}

.mt8 {
    margin-top: 8px;
}

.mt16 {
    margin-top: 16px;
}
