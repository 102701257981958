.upInSearch {

  &-upHide {
    display: none;
  }

  &-root {
    max-width: 100%;
  }

  &-main {
    padding-top: 1.4vw;
  }

  &-content{
    width: 968px;
  }

  &-title {
    background: $popupTitleBgColor;
    width: 95%;
    height: 32px;
    margin: 6px auto;
  }

  &-titleText {
    width: 96%;
    font-size: 20px;
  }

  &-close {
    background: none;
    border: none;
    &:hover {
      .upInSearch-closeIcon {
        display: none;
      }

      .upInSearch-closeIconHov {
        display: block;
      }
    }
  }

  &-closeIconHov {
    display: none;
  }

  &-upInTown {
    margin-bottom: 24px;
  }

  &-upInTownText {
    width: 64%;
    font-size: 32px;
    line-height: 1.2;
  }

  &-upArrow {
    margin-bottom: 32px;
  }

  &-picture {
    margin-right: 24px;
  }

   &-columnButton {
     width: 312px;
     margin-bottom: 24px;
     margin-top: 8px;

    &:hover {

      .upInSearch-up {
        display: none;
      }

      .upInSearch-upHide {
        display: block;
      }

    }
  }

  &-activate {
    // border: 3px solid #dbc758;
    margin-bottom: 24px;
    width: 314px;
    &:hover {
      color: $black;
      background: #7dbae4;
      box-shadow: none;
    }
  }

  &-textWrapper {
    margin-bottom: -24px;
  }

  &-countColumn {
    font-size: 100px;
    text-shadow: 0px 2px 0px $white, 0px -2px 0px $white,
    2px 0px 0px $white, -2px 0px 0px $white,
    -2px -2px 0px $white, 2px 2px 0px $white, 2px -2px 0px $white,
    -2px 2px 0px $white, 2px -2px 0px $white;
  }

  &-monetColumn {
    font-size: 30px;
    margin-bottom: -32px;
    margin-left: 40px;
  }

  &-columnTextWrapper {
    margin-left: -48px;
    margin-bottom: -120px;
    position: relative;
  }

  &-10coinsIcon {
    width: 320px;
  }

  &-info {
    color: #679de7;
    width: 89%;
    font-size: 18px;
  }

  &-footer {
    margin-top: 24px;
    width: 100%;
    margin-bottom: 16px;
  }

  &-goldLine {
    margin-bottom: 16px;
    height: 3px;
    width: 90%;
    background: #dbc758;
  }

  &-help {
    width: 280px;
  }

 &-columnButton, &-activate, &-help {
    font-size: 24px;
  }

  &-bottom {
    width: 100%;
  }

  &-logo {
    margin-left: 16px;
  }
  
  &-image {
    width: 156px;
  }

  &-amount {
    font-size: 93px;
    font-weight: 300;
    display: inline-block;
  }

  &-amountType {
    font-size: 33px;
    display: inline-block;
  }

  &-icon {
    // margin-top: 8px;
    align-self: flex-end;
    // max-width: 7em;
    // margin-bottom: -2.3em;

    // max-width: 178px;
    // margin-bottom: -32px;
    min-height: 85px;
    object-fit: contain;
    margin-top: -97px;
    // margin-right: 9%;
  }

  &-arrow {
    // margin-top: 8px;
    align-self: flex-end;
    // margin-bottom: -101px;
    // max-width: 7em;
    // margin-bottom: -2.3em;

    // max-width: 178px;
    // margin-bottom: -32px;
    // min-height: 85px;
    // object-fit: contain;
    // margin-top: 21px;
    // margin-right: 9%;
  }

  &-optionShortDescr{
    @include coinShortDescr
    // background: $contentOrderVipOptionDescrColor;
    // border-radius: .4em;
    // padding: .3em 1em;
  }

  &-middle &-amount {
    margin-top: -122px;
    align-self: flex-start;
  }

  &-middle &-amountType {
    margin-top: -12px;
    align-self: flex-end;
    padding-right: 42px;
  }

  // &-middle &-priceWrapper {
  //   margin-top: -49px;
  //   align-self: flex-end;
  // }
}

@media(max-width: $large-media) {
  .upInSearch {
    &-content {
      width: 98%;
    }
  }
}

@media(max-width: $medium-media) {
  .upInSearch {
    // &-content {
    //   background-image: url(../img/upInSearchBckgrnMobile.jpg);
    // }

    &-title {
      // background: linear-gradient(90deg, rgba(151,87,150,1) 0%, rgba(254,87,205,1) 50%, rgba(151,87,150,1) 100%);
    background: #ed6b0b;
      height: 5vw;
    }

    &-infoWrapper {
      order: 4;
    }

    &-activate {
      order: 5;
    }

    &-titleText {
      font-size: 3vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 4vw;
      height: 4vw;
    }

    &-upInTownText {
      font-size: 4vw;
    }

    &-countColumn {
      font-size: 13vw;
    }

    &-columnTextWrapper {
      margin-bottom: -16.2vw;
    }

    &-monetColumn {
      font-size: 4vw;
      margin-bottom: -4.2vw;
      margin-left: 5.2vw;
    }

    &-info {
      font-size: 2.3vw;
    }

    &-columnButton {
      width: 41.4%;
      margin-bottom: 9.2vw;
      margin-top: 5.1vw;
    }

    &-activate, &-columnButton, &-help {
      font-size: 3.1vw;
    }

    &-upArrow {
      width: 43%;
      margin-bottom: 6.6vw;
    }

    &-picture {
      margin-right: 4.5vw;
      width: 92.4%;
    }

    &-monetIcon {
      width: 50%;
    }

    &-10coinsIcon {
    width: 85%;
    }

    &-columnTextWrapper {
      margin-bottom: -15vw;
      margin-left: -6vw;
    }

    &-activate {
      margin-bottom: 0;
      width: 41.8%;
    }

    &-footer {
      margin-top: 2vw;
      margin-bottom: 6vw;
    }

    &-help {
      width: 41.8%;
    }

    &-title {
      height: 8vw;
    }

    &-titleText {
      font-size: 5vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 5vw;
      height: 5vw;
    }
  }
}
