.strangersProfileMain {
  height: 65vw;
  margin-top: -3.8vw;
  background-size: cover;
  z-index: 0;
  position: relative;
  padding-top: 3.8vw;

  &-content {
    padding-top: 11.6vw;
  }

  &-buttonTop {
    position: absolute;
    top: -1.4vw;
    font-size: .8vw;
    z-index: 1;
    padding: .49vw 1.7vw;

  }

  &-profileInTop {
    width: 40.3%;
    margin-left: 3.6vw;
    background: #fff;
    position: relative;
    border-radius: 15px;
    padding: 24px 0;
    &:before {
      content: "";
      position: absolute;
      top: -7px;
      bottom: -7px;
      left: -7px;
      right: -7px;
      background: linear-gradient(0deg, rgba(157, 100, 24, 1) 0%, rgba(243, 236, 114, 1) 46%, rgba(220, 176, 94, 1) 100%);
      border-radius: 15px;
      z-index: -1;
    }
  }

  &-carousel {

    .strangersProfileMain-item img {
      height: 13.1vw;
    }

    .strangersProfileMain-item {
      .strangersProfileMain-coronaInTop {
          height: auto;
          position: absolute;
          width: 25%;
      }
    }
  }

  &-inTopPhotoWrapper {
    margin: 0.3vw auto;
    width: 80%;
    border: 5px solid $orangeBackground;
    background-color: $orangeBackground;;
    border-radius: 10px;
    position: relative;
  }

  &-shortInfoBar {
    background: rgba(0,0,0,.5);
    color: #fff;
    position: absolute;
    width: 100%;
    bottom: 0.4vw;
    padding: 0.2vw 0 0.2vw 1.17vw;
  }

  &-nameBar {
    font-size: 1.1vw;
    overflow-wrap: break-word;
  }

  &-yearsBar {
    font-size: 1.1vw;
  }

  &-smallLetter {
    font-size: 0.7vw;
  }

  &-cityBar {
    overflow-wrap: break-word;
  }

  &-inTopPhoto {
    width: 100%;
    border-radius: 10px;
  }

  &-coronaInTop {
    position: absolute;
    width: 22%;
    margin: 0.3vw 0.3vw;
  }

  &-inbox {
    font-size: 0.8vw;
  }

  &-wasOnline {
    font-size: 0.8vw;
  }

  &-buttonWrapper {
    width: 50%;
    padding-left: 1vw;
  }

  &-strangersButton {
    background: none;
    border: none;
    width: 45%;
    font-size: 1vw;
  }

  &-messageIcon {
    margin-top: 0.2vw;
    width: 66%;
    height: 2.6vw;
  }

  &-giftIcon {
    margin-top: 0.2vw;
    width: 59%;
    height: 2.6vw;
  }

  &-buttonLoupe {
    width: 38%;
    height: 2.2vw;
    position: relative;
    border: 4px solid #dbc758;
    border-radius: 4px;
    color: #f48400;
    &:hover {
      background: #7dbae4;
    }
  }

  &-msgWrapper {
    width: 56%;
  }

  &-senderAge {
    font-size: 0.9vw;
  }

  &-topLoupe {
    position: absolute;
    top: -8px;
    right: 16px;
    width: 60%;
  }

  &-inboxMsgItem {
    background: none;
    border: none;
  }

  &-viewOther {
    padding-left: 1.6vw;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &-viewTop {
    width: 54%;
  }

  &-senderAvatar {
    width: 31%;
    border-radius: 10px;
  }

  &-inboxMsgItem  {
    position: relative;
    width: 100%;
    margin-top: .8vw;
    padding-left: 3%;
  }

  &-senderInfo {
    margin: 0 .3vw;
    width: 67%;
  }

  &-senderName, &-senderSecondName, &-senderSurname {
    font-size: .86vw;
    overflow-wrap: break-word;
    width: 100%;
  }

  &-senderCity {
    font-size: .8vw;
  }

  &-msgCount {
    font-size: 1.2vw;
  }

  &-msgIconUnread {
    background: #22a428;
    width: 38%;
    border-radius: 4px;
  }

  &-msgIconRead {
    width: 38%;
    border-radius: 4px;
  }

  &-myProfilePhotos {
    margin-left: 2.2vw;
    width: 43.98%;
  }

  &-mainAvatar {
    position: relative;
  }

  &-avatar {
    width: 100%;
    border-radius: 10px;
  }

  &-coronaOnAvatar {
    position: absolute;
    left: 0.4vw;
    bottom: 0.4vw;
    width: 35%;
  }

  &-likeWrapper,
  &-menuWrapper {
    position: absolute;
    right: .4vw;
    bottom: .4vw;
    width: 25%;
    background: none;
    border: none;
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
    }
  }

  &-likeWrapper{
    &--gallery {
      width: 48pt;
    }
  }


  &-menuWrapper {
    opacity: 0;
    &:hover {
      transform: none;
      opacity: 1;
    }
  }

  &-like,
  &-menu {
    width: 100%;
  }

  &-like.disabled {
    opacity: .2;
    -webkit-filter: drop-shadow(2px 1px 0 #e8da97)
                  drop-shadow(-1px -1px 0 #e8da97);
    filter: drop-shadow(2px 1px 0 #e8da97)
            drop-shadow(-1px -1px 0 #e8da97);
  }

  &-likes_count {
    text-shadow: .1vw .1vw .1vw rgba(150,150,150,.5);

    position: absolute;
    // bottom: 27%;
    color: $likes_countColor;
    // color: white;
    // right: 43%;
    // font-size: 1.1vw;

    bottom: 0;
    right: 0;
    top: 0;
    font-size: .8em;
    width: 100%;
    line-height: 2.9em;
  }

  &-photoWrapper {
    margin: .5vw 0;
  }

  &-noPhotoIcon {
    width: 95%;
    border-radius: 10px;
    border: 4px solid #e8da97;
  }

  &-myPhotoButton {
    width: 56%;
    margin: 0 auto;
    font-size: 1.1vw;
  }

  &-pinkButton {
    position: relative;
    border: 3px solid #dacb56;
    border-radius: 2px;
    font-size: 1vw;
    padding-right: 1.5vw;
    &:before {
      content: "";
      position: absolute;
      top: -9px;
      bottom: -9px;
      left: -9px;
      right: -9px;
      background: linear-gradient(0deg, rgba(157, 100, 24, 1) 0%, rgba(243, 236, 114, 1) 46%, rgba(220, 176, 94, 1) 100%);
      border-radius: 8px;
      z-index: -1;
    }
    &:hover {
      background: #7dbae4;
      color: $black;
    }
  }

  &-aboutMySelf {
    margin-left: 2vw;
    width: 49%;
  }


  &-findButton {
    margin-top: 1.4vw;
    margin-bottom: .8vw;
  }

  &-loupe {
    position: absolute;
    top: -1.4vw;
    right: -1.9vw;
    width: 43%;
  }

  &-infoItem {
    margin: 0.6vw 0;
  }

  &-icon {
    width: 8%;
    margin-right: 0.5vw;
  }

  &-aboutMySelfLeftColumn {
    width: 38%;
  }

  &-menuAndAboutMyself {
    margin-left: 1vw;
    max-width: 59%;
  }

  &-conciseInfo {
    margin-left: 0.2vw;
    margin-bottom: 1.6vw;
  }

  &-name {
    font-size: 2vw;
  }

  &-infoItems {
    font-size: 1.2vw;
    & img {
      width: 10%;
    }
  }

  &-purpleItem {
    font-size: 1vw;
  }

  &-youProfileInTopText {
    margin-top: -1vw;
  }

  &-buttonsBlock {
    padding: 0.3vw 0 0;
    border-radius: 5px;
    border: 1px solid rgba(255,255,255, 0.4);
    width: 7.6vw;
    height: 6.3vw;
    margin: 0 0.2vw;
  }

  &-simpleButton {
    font-size: 0.9vw;
    background: none;
    border: none;
    text-decoration: underline;
    color: $white;
  }

  &-purpleWrap {
    background: rgba(112, 43, 136, 0.6);
    padding: 0.2vw 0.2vw 0.2vw 0.2vw;
    border-radius: 5px;
  }

  &-findInformation {
    width: 48%;
  }

  &-aboutMe {
    margin-top: 0.2vw;
  }

  &-textInBubble {
    font-size: 0.9vw;
  }

  &-bubble {
    font-size: 0.9vw;
  }

  &-topAndPhotosMobile {
    width: 36.7%;
  }

}

@media(max-width: $extraLarge-media) {
  .strangersProfileMain {
    &-name {
      font-size: 20px;
    }

    &-infoItems {
      font-size: 12px;
    }

    &-infoItem {
      margin: .3vw 0;
    }

    &-infoItems {
      & img {
        width: 13%;
      }
    }

    &-profileInTop {
      border-radius: 12px;

      &:before {
        top: -4px;
        bottom: -4px;
        left: -4px;
        right: -4px;
      }
    }

    &-pinkButton:before {
      top: -6px;
      bottom: -6px;
      left: -6px;
      right: -6px;
      border-radius: 5px;
    }

    &-wasOnline {
      font-size: 1vw;
      height: 1vw;
      margin: -1.7vw 0 0.7vw 3vw;
    }

    &-textInBubble {
      font-size: 12px;
    }

    &-purpleItem {
      font-size: 12px;
    }

  }
}

@media(max-width: $large-media) {
  .strangersProfileMain {

    &-conciseInfo {
      background: rgba(112,43,136,.5);
      padding: 0.1vw 1vw;
      border-radius: 5px;
    }

    &-content {
      justify-content: center;
    }

    &-profileInTop {
      width: 44.8%;
      margin-left: 1vw;
    }

    &-inTopPhotoWrapper {
      border: 2px solid #ff9306;
      width: 61%;
    }

    &-buttonTop {
      font-size: 1vw;
    }

    &-inboxMsgItem {
      width: 90%;
    }

    &-messageText {
      font-size: 1.2vw;
    }

    &-yearsBar, &-cityBar, &-nameBar {
      font-size: 1.1vw;
    }

    &-shortInfoBar {
      border-radius: 5px;
    }

    &-msgIconRead, &-msgIconUnread {
      width: 32%;
      border-radius: 2px;
    }

    &-aboutMySelf {
      width: 52%;
    }

    &-aboutMe {
      margin: 1vw 0;
      background: rgba(112,43,136,.5);
      padding: 1.1vw 1vw;
      border-radius: 5px;
    }

    &-purpleWrap {
      background: none;
      padding: .2vw;
    }

    &-icon {
      margin-right: 10px;
    }

    &-myProfilePhotos {
      margin-left: 2.2vw;
      width: 63%;
    }

    &-photoWrapper {
      height: 9.8vw;
    }

    &-myPhotoButton {
      width: 68%;
      font-size: 1.5vw;
      background: $white;
    }

    &-name {
      font-size: 2vw;
      margin-bottom: 2vw;
    }

    &-whiteItem {
      font-size: 1.6vw;
    }

    &-menuAndAboutMe {
      justify-content: space-evenly;
    }

    &-strangersButton {
      font-size: 1.3vw;
    }

    &-findButton {
      margin: 1.8vw;
      width: 100%;
    }

    &-pinkButton {
      position: relative;
      border: 3px solid #dacb56;
      border-radius: 2px;
      font-size: 1.3vw;
    }

    &-loupe {
      top: -2.05vw;
      right: -2.5vw;
      width: 47%;
    }

    &-wasOnline {
      font-size: 1.2vw;
      margin-bottom: 0.8vw;
      text-align: center;
      // color: white;
    }

    &-textInBubble {
      font-size: 1.2vw;
    }

    &-textInBubble {
      font-size: 1.2vw;
    }

    &-simpleButton {
      font-size: 1.2vw;
    }

    &-messageIcon {
      width: 77%;
      height: 3vw;
    }

    &-giftIcon {
      width: 69%;
      height: 3.1vw;
    }

    &-bubble {
      font-size: 1.3vw;
    }

    &-topAndPhotosMobile {
      width: 43.7%;
    }

    &-senderName, &-senderSecondName, &-senderSurname {
      font-size: 1.2vw;
    }

    &-topLoupe {
      top: -0.9vw;
      right: 0.5vw;
      width: 60%;
    }

    &-buttonLoupe {
      height: 3.2vw;
    }

    &-viewTop {
      font-size: 1vw;
    }

    &-profileInTop {
      align-self: flex-start;
    }

  }
}

@media(max-width: $medium-media) {
  .strangersProfileMain {
    background-image: url(../img/myProfileMain-mobile.png);
    height: auto;
    margin-top: -0.8vw;
    background-size: unset;
    z-index: 0;
    position: relative;

    &-content {
      margin-top: -1vw;
      padding-top: 0;
    }

    &-avatar {
      height: 34.7vw;
    }

    &-infoItems {
      & img {
        width: 15%;
      }
    }

    &-topAndPhotosMobile {
      justify-content: space-between;
      width: 34%;
      flex-direction: column-reverse;
    }

    &-aboutMySelf {
      width: 65%;
      margin-left: 0;
    }

    &-profileInTop {
      padding: 0;
      background: none;
      flex-direction: column-reverse;
      width: 95.8%;
      &:before {
        display: none;
      }
    }

    &-carousel {
      .strangersProfileMain-item img {
        height: 36vw;
      }
    }

    &-name {
      color: $black;
      font-size: 7vw;
    }

    &-wasOnline {
      font-size: 3.3vw;
      text-align: unset;
      margin-bottom: 0;

      margin-top: -6.5vw;
      margin-bottom: 3vw;
      padding-left: 2vw;
    }

    &-messages {
      background: none;
      border: none;
      &:focus {
        outline: none;
      }
      &:hover {
        .strangersProfileMain-pinkMessageIcon {
          transform: scale(1.2);
        }
      }
    }

    &-menuAndAboutMe {
      justify-content: space-between;
    }

    &-pinkMessageIcon {
      width: 18vw;
    }

    &-messQuantity {
      width: 100%;
      font-size: 5vw;
    }

    &-inTopPhoto {
      height: 35.2vw;
    }

    &-inTopPhotoWrapper {
      width: 100%;
      border: 4px solid #ff9306;
    }

    &-cityBar, &-nameBar, &-yearsBar {
      font-size: 3.4vw;
      letter-spacing: -1px;
    }

    &-msgIconRead, &-msgIconUnread {
      width: 50%;
    }

    &-smallLetter {
      font-size: 3vw;
    }

    &-inbox {
      align-items: start;
      width: 100%;
      padding-bottom: 6vw;
    }

    &-inboxMsgItem {
      flex-direction: column;
      align-items: start;
      width: 100%;
      margin-bottom: 2vw;
    }

    &-coronaInTop {
      display: none;
      position: absolute;
      bottom: 11vw;
      width: 31%;
      margin: .3vw;
    }

    &-msgCount {
      margin-bottom: 5vw;
      font-size: 6.8vw;
    }

    &-senderInfo {
      width: 100%;
    }

    &-senderAvatar {
      width: 50%;
    }

    &-senderName {
      letter-spacing: -1px;
      font-size: 4.2vw;
    }

    &-senderAge {
      font-size: 4.2vw;
      margin-right: 1vw;
    }

    &-senderCity {
      letter-spacing: -1px;
      font-size: 3.9vw;
      margin: 0;
    }

    &-myProfilePhotos {
      margin-left: 1vw;
      width: 86%;
      margin-top: -13.5vw;
      align-items: center;
    }

    &-pinkButtonAboutMe {
      position: relative;
      border: 3px solid #dacb56;
      border-radius: 2px;
      font-size: 3vw;
      padding: 0.4vw 0;
      width: 95%;
      margin-top: -3vw;
      box-shadow: inset 0px 7px 15px -4px rgba(0,0,0,0.55);
      &:before {
        content: "";
        position: absolute;
        top: -9px;
        bottom: -9px;
        left: -9px;
        right: -9px;
        background: linear-gradient(0deg, rgba(157, 100, 24, 1) 0%, rgba(243, 236, 114, 1) 46%, rgba(220, 176, 94, 1) 100%);
        border-radius: 6px;
        z-index: -1;
      }
      &:hover {
        background: #7dbae4;
        color: $black;
      }
    }


    &-anotherPhoto {
      margin-top: 0.6vw;
    }

    &-mainAvatar {
      position: relative;
      z-index: -1;
    }

    &-simpleButton {
      color: $black;
      font-size: 5vw;
    }

    &-anotherPhotos {
      margin-top: 1vw;
    }

    &-buttonTop {
      bottom: 32.6vw;
      top: auto;
      padding: .9vw 5.6vw;
      border: 4px solid #fff;
      font-size: 2.7vw;
      z-index: 2;
    }

    &-aboutMeModule {
      justify-content: center;
    }

    &-menuAndAboutMyself {
      margin-left: 0;
      width: 100%;
      max-width: 100%;
    }

    &-arrowUp {
      & img {
        margin-top: -3.8vw;
      }
    }

    &-information {
      position: relative;
      background: #ffffff;
      border: 2px solid rgba(133, 133, 133, 0.4);
      border-radius: 10px;
      z-index: 0;
    }

    &-pinkButton {
      position: absolute;
      right: 8vw;
      top: 3vw;
      width: 45%;
      font-size: 3.1vw;
      box-shadow: inset 0 7px 15px -4px rgba(0,0,0,.55);
      &:before {
        content: "";
        position: absolute;
        top: -9px;
        bottom: -9px;
        left: -9px;
        right: -9px;
        background: linear-gradient(0deg, rgba(157, 100, 24, 1) 0%, rgba(243, 236, 114, 1) 46%, rgba(220, 176, 94, 1) 100%);
        border-radius: 8px;
        z-index: -1;
      }
    }

    &-icon {
      width: 15%;
      margin-right: 5px;
    }

    &-loupe {
      top: -3.9vw;
      right: -8.5vw;
      width: 46%;
    }

    &-findInformation {
      width: 100%;
      padding-left: 1.1vw;
      letter-spacing: -1px;
    }

    &-purpleItem, &-whiteItem {
      font-size: 4.6vw;
      margin: 2vw 0;
    }

    &-goldBorder {
      border: 2px solid #dbc758;
      border-radius: 10px;
      padding: 0 4px;
    }

    &-buttonWrapper {
      width: 60%;
      padding-left: 0;
      align-items: flex-end;
      margin-bottom: 2vw;
    }

    &-messageIcon {
      width: 90%;
      height: 9vw;
    }

    &-giftIcon {
      width: 90%;
      height: 11vw;
    }

    &-likeWrapper,
    &-menuWrapper {
      right: -3.6vw;
      bottom: 1.4vw;
      width: 36%;
    }

    &-menuWrapper {
      width: 36%;
    }

    &-myProfile {
      width: 100%;
      margin: 2.5vw 0;
    }

    &-myProfileButton {
      position: relative;
      border: 3px solid #dacb56;
      border-radius: 2px;
      font-size: 3vw;
      padding: .4vw 0;
      width: 95%;
      box-shadow: inset 0 7px 15px -4px rgba(0, 0, 0, 0.55%);
      &::before {
        content: "";
        position: absolute;
        background: linear-gradient(0deg,#9d6418 0,#f3ec72 46%,#dcb05e 100%);
        z-index: -1;
        top: -9px;
        bottom: -9px;
        left: -9px;
        right: -9px;
        border-radius: 6px;
      }
    }

    &-home {
      position: absolute;
      top: -3.9vw;
      right: -6vw;
      width: 42%;
    }
  }
}

@media(max-width: $small-media) {
  .strangersProfileMain {
    &-purpleItem, &-whiteItem {
      font-size: 4.6vw;
    }

    &-pinkButtonAboutMe:before {
      content: "";
      position: absolute;
      top: -5px;
      bottom: -5px;
      left: -5px;
      right: -5px;
      background: linear-gradient(0deg,#9d6418 0,#f3ec72 46%,#dcb05e 100%);
      border-radius: 6px;
      z-index: -1;
    }

    &-pinkButton:before {
      top: -5px;
      bottom: -5px;
      left: -5px;
      right: -5px;
      border-radius: 6px;
    }

    &-inbox {
      padding-bottom: 3vw;
    }

    &-buttonTop {
      bottom: 35vw;
      top: auto;
      padding: .9vw 5vw;
      border: 2px solid #fff;
      font-size: 2.5vw;
    }

    &-coronaInTop {
      bottom: 17vw;
    }
  }
}
