.placeProfileInTopCountry {

  &-root {
    max-width: 100%;
  }

  &-content{
    // background-image: url(../img/profileInTopBackgroud.jpg);
    width: 600px;
  }

  &-wrapper {
    width: 100%;
  }

  &-title {
    margin-bottom: 8px;
  }

  &-titleText {
    width: 75%;
    background: $blue;
    font-size: 18px;
    margin-right: 48px;
  }

  &-titleTextBottom {
    width: 75%;
    color: $blue;
    font-size: 18px;
  }

  &-close {
    background: none;
    border: none;
    &:hover {
      .placeProfileInTopCountry-closeIcon {
        display: none;
      }

      .placeProfileInTopCountry-closeIconHov {
        display: block;
      }
    }
  }

  &-closeIconHov {
    display: none;
  }

  &-button {
    background: $white;
    position: relative;
    width: 312px;
    height: 44px;
    margin: 28px 0;

    &:hover {
      .placeProfileInTopCountry-howLong {
        display: block;
      }

      .placeProfileInTopCountry-buttonText {
        display: none;
      }
    }
  }

  &-howLong {
    font-size: 18px;
    display: none;
    color: $black;
  }

  &-coinCount {
    // position: absolute;
    // top: -53px;
    // right: -70px;
    // right: 288px; 
    position: inherit;
    margin-right: 186px;
  }

  &-image {
    // margin-top: -22px;
    margin-top: -14px;
    margin-right: -169px; 
  }

  &-coinWrapper {
    display: flex;
    align-items: flex-end;
  }

  &-number {
    display: block;
    font-size: 71px;
    margin-right: -85px;
  }

  &-word {
    font-size: 22px;
    // margin-right: 40px;
    margin-right: -88px;
    margin-top: -19px;
  }

  &-menu {
    margin-top: 24px;
    margin-left: 16px;
    width: 71%;
  }

  &-buttonWrapper {
    height: 100%
  }

  &-brownLine {
    background: #ed6b0b;
    height: 3px;
    width: 92%;
    margin: 2% auto;
  }

  &-inTopWrapper {
    width: 28%;
  }

  &-viewOther {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &-viewTop {
    margin-top: 8px;
  }

  &-inTopPhotoWrapper {
    margin-top: 0.3vw;
    border: 5px solid $orangeBackground;
    background-color: $orangeBackground;;
    border-radius: 10px;
    position: relative;
    margin-left: 12px;
    margin-top: 22px;
    width: 92%;
  }

  &-buttonLoupe {
    width: 100px;
    height: 50px;
    position: relative;
    border: 4px solid #dbc758;
    border-radius: 4px;
    color: #f48400;
    &:hover {
      background: #7dbae4;
    }
  }

  &-loupe {
    position: absolute;
    top: -8px;
    right: 16px;
    width: 60%;
  }

  &-inTopPhoto {
    width: 100%;
    border-radius: 8px;
  }

  &-buttons {
    padding: 0 16px;
  }

  &-buttonsBlock {
    width: 100%;
    margin-bottom: 8px;
  }

  &-help, &-share {
    width: 48%;
    height: 38px;
    font-size: 19px;
  }

  &-share {
    // border: 3px solid #dbc758;
    &:hover {
      color: $black;
      background: #7dbae4;
    }
  }

  &-greeting {
    position: relative;
    width: 81%;
    height: 40px;
    align-self: flex-end;
    margin: 14px 0;
    border: 1px solid #dcb05e;
    border-radius: 10px;
    box-shadow: inset 1px 1px 5px -1px rgba(0,0,0,0.49);
    padding-left: 8px;

    &:focus {
      outline: none;
    }
  }

  &-coronaInTop {
    position: absolute;
    width: 35%;
  }

 }

@media(max-width: $medium-media) {
  .placeProfileInTopCountry {
    &-content{
      // background-image: url(../img/profileInTopBackgrMobile.jpg);
      // background-position: center;
      width: 95%;
    }

    &-blueLine {
      background: $blue;
    }

    &-title {
      margin: 6px 3px;
    }

    &-titleText {
      background: none;
      margin-right: 0;
    }

    &-closeIcon, &-closeIconHov {
      width: 4vw;
      height: 4vw;
    }

    &-titleTextBottom {
      font-size: 4.2vw;
      width: 100%;
    }

    &-menu {
      width: 100%;
      margin-left: 0;
      margin-top: 5px;
      margin-bottom: 5vw
    }

    &-number {
      font-size: 9.2vw;
      margin-right: -11vw;
    }

    &-word {
      font-size: 2.9vw;
      // margin-right: 5.2vw;
      margin-top: -2.5vw;
      margin-right: -11.8vw;
    }

    &-image {
      // width: 17vw;
      margin-top: -2.9vw;

    }

    &-viewTop {
      font-size: 3vw;
      width: 40%;
    }

    &-buttonLoupe {
      width: 22%;
      height: 9vw;
    }

    &-coinCount {
      // top: -7vw;
      // right: -2.1vw;
      position: inherit;
      margin-right: 7vw;
    }

    &-button {
      margin: 35px auto;
      width: 71%;
      height: 53px;
    }

    &-greeting {
      margin: 24px auto 0;
      width: 97%;
      height: 45px;
      font-size: 21px;
    }

    &-brownLine {
      height: 4px;
      width: 93%;
      background: #dbc758;
      margin: 0 auto;
    }

    &-viewOther {
      margin-bottom: 5vw;
    }

    &-buttonsBlock {
      flex-direction: column-reverse;
    }

    &-help, &-share {
      margin: 8px auto;
      font-size: 24px;
    }

    &-share {
      width: 71%;
      height: 56px;
    }

    &-help {
      width: 65%;
      height: 48px;
    }

    &-titleText {
      font-size: 4.2vw;
    }

    &-buttonText {
      color: $black;
      font-size: 3.7vw;
      letter-spacing: 0px;
    }

    &-loupe {
      position: absolute;
      top: -1.1vw;
      right: 2vw;
    }

  }
}

@media(max-width: $small-media) {
  .placeProfileInTopCountry {

    &-number {
      font-size: 13.2vw;
    }

    &-word {
      font-size: 4.9vw;
    }

    &-image {
      // width: 24vw;
      width: 15vw;
    }

    &-button {
      margin: 35px auto;
      width: 80%;
    }

    &-help, &-share {
      font-size: 5vw;
      width: 70%;
      height: auto;
    }

    &-greeting {
      font-size: 5vw;
    }

    &-buttonText {
      font-size: 4.5vw;
    }

    &-buttonLoupe {
      height: 12vw;
    }

    &-coinCount {
      top: -7vw;
      right: -5.6vw;
    }

  }
}