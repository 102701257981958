.findSettings {

  &-root {
    max-width: 100%;
  }

  &-main {
    padding-top: 1.4vw;
  }

  &-content{
    width: 966px;
  }

  &-title {
    // background: linear-gradient(90deg, rgba(151,87,150,1) 0%, rgba(254,87,205,1) 50%, rgba(151,87,150,1) 100%);
    background: $popupTitleBgColor;
    width: 97%;
    height: 32px;
    margin: 6px 0 48px;
  }

  &-titleText {
    font-size: 20px;
    width: 96%;
  }

  &-closeIconHov {
    display: none;
  }

  &-close {
    background: none;
    border: none;
    &:hover {
      .findSettings-closeIcon {
        display: none;
      }

      .findSettings-closeIconHov {
        display: block;
      }
    }
  }

  &-container {
    width: 100%;
  }

  &-left, &-right {
    width: 45%;
    padding-left: 48px;
  }

  &-left {
    margin-left: 48px;
  }

  &-icon {
    width: 2.3vw;
  }

  &-buttonWrapper {
    position: relative;
  }

  &-infoItem {
    margin-bottom: 8px;
    background: none;
    border: none;
    &::after {
      display: inline-block;
      margin-left: .255em;
      vertical-align: .255em;
      content: "";
      border-top: .3em solid orange;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
    }
  }

  &-purpleItem {
    font-size: 21px;
    margin-left: 8px;
    border: 2px solid #dbc758;
    border-radius: 10px;
    padding: 2px 4px;;
  }

  &-age {
    width: 72px;
  }

  &-location {
    width: 176px;
  }

  &-dropdownTitle {
    font-size: 20px;
  }

  &-label {
    margin-left: 4px;
  }

  &-small {
    font-size: 12px;
    width: 228px;
    margin: 4px 0;
  }

  &-financStatus {
    width: 192px;
  }

  &-search {
    border: 3px solid #dbc758;
    width: 36%;
    font-size: 25px;
    margin: 40px 0 16px;
    &:hover {
      color: #000;
      background: #7dbae4;
      box-shadow: none;
    }
  }

  &-footer {
    margin-top: 8px;
    width: 100%;
    margin-bottom: 16px;
  }

  &-goldLine {
    margin-bottom: 16px;
    height: 3px;
    width: 90%;
    background: #dbc758;
  }

   &-help {
     width: 28%;
     font-size: 24px;
  }

  &-bottom {
    width: 100%;
  }

  &-logo {
    margin-left: 16px;
  }
}


@media(max-width: $large-media) {
  .findSettings {

    &-content {
      width: 98%;
    }

    &-icon {
      width: 3.5vw;
    }

    &-right {
      padding-left: 0;
    }

    &-left {
      width: 44%;
      padding-left: 24px;
    }

    &-search {
      width: 32%;
      font-size: 2.4vw;
    }

    &-purpleItem {
      font-size: 1.8vw;
      margin-left: 0.7vw;
      padding: 0.2vw 0.4vw;
    }

    &-footer {
      margin-top: 2.8vw;
      margin-bottom: 1.4vw;
    }

    &-goldLine {
      margin-bottom: 1.4vw;
    }

    &-help {
      font-size: 2vw;
    }

    &-logo {
      margin-left: 1.4vw;
      width: 28%;
    }

    &-button {
      width: 23%;
    }

  }
}

@media(max-width: $medium-media) {
  .findSettings {
    &-content {
      width: 98%;
    }

    &-content {
      width: 98%;
    }

    &-container {
      flex-direction: column;
      align-items: center;
      height: 123vw;
      overflow-y: scroll;
    }

    &-left, &-right {
      width: 87%;
      padding-left: 0;
      margin-left: 0;
    }

    &-icon {
      width: 10.3vw;
    }

    &-title {
      // background: linear-gradient(90deg, rgba(151,87,150,1) 0%, rgba(254,87,205,1) 50%, rgba(151,87,150,1) 100%);
    background: #ed6b0b;
      justify-content: flex-end;
      height: 5vw;
    }

    &-dropdownTitle {
      font-size: 4vw;
    }

    &-small {
      font-size: 2vw;
      width: 100%;
      margin: 0.5vw 0;
    }

    &-list {
      position: absolute;
      inset: unset !important;
      margin: 0px;
      transform: unset !important;
      left: 48px !important;
      top: 65px !important;
      padding: 1vw 2vw;
      font-size: 3.4vw;
    }

    &-titleText {
      font-size: 3vw;
    }

    &-buttonWrapper {
      margin: 3vw 0vw;
    }

    &-purpleItem {
      font-size: 4.5vw;
      padding: .5vw 1vw;
    }

    &-infoItem::after {
      border-top: 2.4vw solid orange;
      border-right: 2.4vw solid transparent;
      border-left: 2.4vw solid transparent;
    }

    &-search {
      width: 69%;
      font-size: 5.4vw;
      margin: 2vw  0 3vw;
    }

    &-help {
      width: 63%;
      font-size: 5vw;
    }

    &-footer {
      margin-top: 2vw;
      margin-bottom: 6vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 4vw;
      height: 4vw;
    }

    &-scrollButtonHover {
      display: none;
    }

    &-scroll {
      background: none;
      border: none;
      &:hover {
        .findSettings-scrollButton {
          display: none;
        }

        .findSettings-scrollButtonHover {
          display: block;
        }
      }
    }

    &-scrollButton, &-scrollButtonHover {
      width: 13vw;
      height: 13vw;
    }

    &-title {
      height: 8vw;
    }

    &-titleText {
      font-size: 5vw;
    }


  }
}

@media(max-width: $small-media) {
  .findSettings {
    &-itemsWrapper {
      width: 98%;
    }

    &-purpleItem {
      font-size: 3.9vw;
    }

    &-title {
      height: 8vw;
      margin: 6px auto 1vw;
    }

    &-titleText {
      font-size: 5vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 5vw;
      height: 5vw;
    }

    &-left, &-right {
      width: 90%;
    }
  }
}

