@mixin coinShortDescr {
  background: $contentOrderVipOptionDescrColor;
  border-radius: .4em;
  padding: .3em 1em;
}

// .coin {
//   &-optionShortDescr{
//     @include baseBtn;
//   }
// }


// @mixin baseBtn {
//   background: $btnBgColor1;
//   &:hover {
//     background: $btnColor1;
//     color: $btnBgColor1;
//   }
// }

// %baseBtn {
//   background: $btnBgColor1;
//   border: $baseBtnBorderThin solid $btnBgColor1;
//   border-radius: 0.7vw;
//   color: $btnColor1;
//   cursor: pointer;
//   &:hover {
//     background: $btnColor1;
//     color: $btnBgColor1;
//   }
// }


// %baseBoxShadow1 {
//   box-shadow: 0 0.1vw 0.5vw rgba(#000000, 0.3);
// }

// %baseBoxShadow {
//   @extend %baseBoxShadow1;
// }

// .base-shadowed {
//   @extend %baseBoxShadow;
// }

// .orangeButton{
//   @include baseBtn;
//   background: $btnBgColor2;
//   border-color: $btnBgColor2;
//   color: $white;
//   // padding: 3pt 17pt;
//   padding: .3vw 1.3vw;
//   &:hover{
//     background: $white;
//     color: $btnBgColor2;
//   }
// }

@media(max-width: $large-media) {

}

@media(max-width: $medium-media) {

}

@media(max-width: $small-media) {

}