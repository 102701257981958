.forgotPasswordModal {
  z-index: 1051;
  &-content {
    width: 344px;
  }

  &-spanForgotPass {
    margin-top: 40px;
    text-transform: uppercase;
    font-weight: 500;
  }

  &-spanText {
    text-align: center;
    font-size: 14px;
    width: 288px;
  }

  &-inputArea {
    width: 288px;
    height: 33px;
    margin-top: 20px;
  }

  &-button {
    font-size: 16px;
    width: 275px;
    margin-top: 22px;
    margin-bottom: 56px;
  }

 }

