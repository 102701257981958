.myProfileFooter {
  &-pinkLine {
    // background-color: #ffedfa;
    background-color: transparent;
    height: 0.5vw;
  }
  &-refs{
    background-color: $white;
    height: 2vw;
  }
  &-href{
    text-decoration: none;
    color: $black;
    margin: 0.4vw 1vw;
    font-size: 0.8vw;
  }

  &-supportButton {
    font-size: 1vw;
    width: 14%;
    height: 1.8vw;
    margin-right: 1vw;
    margin-top: 1vw;
  }

  &-menu {
    margin-bottom: 0.5vw;
  }

  &-chat {
    width: 20%;
  }

  &-chatUs {
    text-align: start !important;
    padding-left: 0.2vw;
    font-size: 0.9vw;
  }

  &-text {
    font-size: 0.8vw;
  }
}

@media(max-width: $extraLarge-media) {
  .myProfileFooter {
    &-refs{
      min-height: 23px;
      background-position: center;
    }
    &-myProfileFooter{
      font-size: 14px;
      margin: 10px 5px;
    }
    &-myProfileFooter{
      height: 8px;
    }
  }
}

@media(max-width: $large-media) {
  .myProfileFooter {
    margin-top: 15vw;
    &-supportButton {
      font-size: 1.4vw;
      width: 21%;
      height: 2.8vw;
      margin-right: 1vw;
      margin-top: 1vw;
    }

    &-chatUs {
      height: 2.7vw;
      padding-left: .7vw;
      font-size: 1.2vw;
    }

    &-href {
      text-decoration: none;
      color: #000;
      margin: .4vw 1vw;
      font-size: 1vw;
    }

    &-text {
      font-size: 1.2vw;
    }
  }
}

@media(max-width: $medium-media) {
  .myProfileFooter {
    position: relative;
    margin-top: 1vw;
    & a {
      text-transform: lowercase;
      text-decoration: underline;
    }

    &-menu {
      align-items: flex-end;
      flex-direction: column-reverse;
      padding: 0vw 3vw;
    }

    &-pinkLine {
      position: absolute;
      width: 100%;
      top: 18.4vw;
      height: 3.8vw;
      z-index: 1;
    }

    &-chatUs {
      height: 7vw;
      width: 100%;
      padding-left: 2vw;
      font-size: 4vw;
    }

    &-chat {
      width: 100%;
    }

    &-copyright{
      order: 4;
    }

    &-nav{
      flex-wrap: wrap;
    }

    &-href{
      z-index: 2;
      margin: 0.7vw;
      font-size: 3vw;
    }

    &-supportButton {
      font-size: 4.4vw;
      width: 100%;
      height: 8.5vw;
      margin-right: 0vw;
      margin-top: 1vw;
    }
  }
}

