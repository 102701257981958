@use 'sass:color';


@mixin baseBtn {
  background: $btnBgColor1;
  border: $baseBtnBorderThin solid $btnBgColor1;
  border-radius: 0.5vw;
  color: $btnColor1;
  cursor: pointer;
  // font-size: .8vw;
  // font-size: clamp(10px, .8vw, 2rem);
  font-size: .8vw;
  font-size: clamp(10px, .8vw, 2rem);
  &:hover {
    background: $btnColor1;
    color: $btnBgColor1;
  }
}

@mixin baseBtn($bordered: true, $shadowed: false) {
  @if $shadowed {
    @include baseBtnShadow;
  }
  font-size: .8vw;
  font-size: clamp(10px, .8vw, 2rem);
  cursor: pointer;
  background: $btnBgColor1;
  color: $btnColor1;
  @if $bordered {
    border: $baseBtnBorderThin solid $btnBgColor1;
  } @else {
    border: none;
  }
  border-radius: 0.5vw;

  &:hover {
    background: $btnColor1;
    color: $btnBgColor1;
  }
}

.blueButton {
  // background: none;
  // border: 3px solid #1e67d9;
  // border-radius: 4px;
  // color: #f48400;
  // cursor: pointer;

  @include baseBtn($bordered: false);
  font-size: .8vw;
  font-size: clamp(10px, .8vw, 2rem);
  background: $btnBgColor1;
  color: $white;
  border: 0px;
  padding: 0.3vw 2.1vw;
  border-radius: 0.5vw;
  border: $btnBgColor1 0.1vw solid;
  border-color: transparent;

  &:hover {
    // color: $black;
    color: #00a0e3;
    background: $white;
    // border-color: $btnBgColor1;
    // outline: 0.01vw #00a0e3 solid ;
  }

}


.goldButton {
  background: none;
  border: 3px solid #dbc758;
  border-radius: 4px;
  color: #f48400;
  cursor: pointer;
}

.goldButton:hover {
  color: $black;
  background: #7dbae4;
  box-shadow: none;
}

.blueButtonMobile {
  background: none;
  border: 2px solid #1e67d9;
  border-radius: 2px;
  color: #6500c1;
  cursor: pointer;
}

.blueButtonMobile:hover {
  background: $white;
  border: 2px solid #1e67d9;
}

.purpleButton {
  background: #6632c2;
  color: $white;
  border: 4px solid $white;
  border-radius: 4px;
  cursor: pointer;
}

.pinkButton {
  background: #fe0094;
  color: $white;
  border-radius: 2px;
  cursor: pointer;
}

// .orangeButton {
//   background: #ed6b0b;
//   color: $white;
//   border: 0px solid $white;
//   border-radius: 0.5vw;
//   cursor: pointer;
// }

// .orangeButton:hover {
//   color: $black;
//   background: $white;
// }

.orangeButton{
  @include baseBtn;
  background: $btnBgColor2;
  border-color: $btnBgColor2;
  color: $white;
  // padding: 3pt 17pt;
  padding: .3vw 1.3vw;
  &:hover{
    background: $white;
    color: $btnBgColor2;
  }
}


.whiteButton {
  background: none;
  border: 0.2vw solid #fff;
  border-radius: 0.7vw;
  color: white;
  cursor: pointer;
}

.whiteButton:hover {
  color: $black;
  background: #fbc59d;
}


.optionButton {
  @include baseBtn($shadowed: true);

  background-color: $white;
  border-color: $white;
  border-width: clamp(2px, .2vw, .7rem) ;
  color: black;
  // padding: 3pt 17pt;
  padding: .3vw 1.3vw;
  &:hover{
    border-color: color.adjust($optionBtnActiveBgColor, $lightness: 11%);;
    color: black;
  }
  &--active {
    border-color: $optionBtnActiveBgColor;
    color: black;
  }
}


@media(max-width: $medium-media) {
  .whiteButton, .orangeButton, .blueButton {
    height: 7vw;
    font-size: 3.2vw;
    border-width: .3vw;
    border-radius: 1.7vw;
  }
  // TODO: add mobile large buttons
  // .mobile-large {
  //   .whiteButton, .orangeButton, .blueButton {
  //     height: 7vw;
  //     font-size: 3.2vw;
  //     border-width: .3vw;
  //     border-radius: 1.7vw;
  //   }
  // }
}
