.aboutMe {

   &-root {
     max-width: 100%;
   }

  &-content {
    padding: 8px 16px;
    width: 76%;
  }

  &-wrapper {
    width: 90%;
    margin-bottom: 32px;
  }

  &-title {
    font-size: 5vw;
    letter-spacing: 2px;
  }

  &-info {
    font-size: 5vw;
  }

  &-close {
    background: none;
    border: none;
    &:hover {
      .aboutMe-closeIcon {
        display: none;
      }

      .aboutMe-closeIconHov {
        display: block;
      }
    }
  }

  &-closeIconHov {
    display: none;
  }
}

@media(max-width: $small-media) {
  .aboutMe {
    &-content {
      padding: 8px 16px;
      width: 100%;
    }
  }
}