.docs {
  h1, h2, h3, h4, h5, h6 {
    // // background: linear-gradient(90deg, rgba(151,87,150,1) 0%, rgba(254,87,205,1) 50%, rgba(151,87,150,1) 100%);
    background: #ed6b0b;
    width: 95%;
    // height: 32px;
    margin: 6px 0;
  }

}

@media(max-width: $extraLarge-media) {
  .docs {
    &-content {
      width: 90%;
    }
  }
}

@media(max-width: $large-media) {
  // .docs {

  // }
}

@media(max-width: $medium-media) {
  .docs {
    &-content {
      width: 98%;
    }

    h1, h2, h3, h4, h5, h6  {
      // // background: linear-gradient(90deg, rgba(151,87,150,1) 0%, rgba(254,87,205,1) 50%, rgba(151,87,150,1) 100%);
    background: #ed6b0b;
      justify-content: flex-end;
      height: 8vw;
    }

    &-titleText {
      font-size: 3vw;
    }
  }
}

@media(max-width: $small-media) {
  // .docs {
  
  // }
}

