.gifts {
  &-root {
    max-width: 100%;
    margin-top: auto;
  }

  &-item {
    // max-height: 100%;
    max-height: 90vh;
    max-height: calc(100vh - 11em);
    overflow-y: auto;
  }

  &-content{
    width: 600px;
    max-height: 100vh;
    max-height: 100%;
    // max-height: 100vh;
    // overflow-y: scroll;
  }

  &-present {
    max-width: 20em;
    max-height: 27em;
  }

  &-wrapper {
    width: 100%;
    max-height: 100%;
    max-height: 100vh;
    overflow-y: scroll;
  }

  &-title {
   width: 100%;
  }

  &-close {
    background: none;
    border: none;
    &:hover {
      .gifts-closeIcon {
        display: none;
      }

      .gifts-closeIconHov {
        display: block;
      }
    }
  }

  &-closeIconHov {
    display: none;
  }

  &-brownLine {
    background: #ed6b0b;
    height: 3px;
    width: 92%;
    margin-top: 4px;
  }

  &-help, &-share {
    width: 48%;
    height: 38px;
    font-size: 19px;
  }

  &-share {
    // border: 3px solid #dbc758;
    &:hover {
      background: #7dbae4;
    }
  }

  &-choose {
    width: 100%;
    padding: 0px 16px;
    position: static;
    position: sticky;
    bottom: 0;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 91%, rgba(255,255,255,0) 100%);
  }

  &-coinCount {
    margin-left: 48px;
  }

  &-button {
    background: $white;
    position: relative;
    width: 48%;
    margin: 28px 0;
    font-size: 18px;
  }

  &-buttons {
    margin-bottom: 8px;
  }

  &-radioItem{
    display: none;
  }

  &-label {
    border: 4px solid transparent;
  }

  &-radioItem:checked + &-label {
    border: 4px solid #dbc758;
    border-radius: 10px;
  }

  &-number {
    margin-bottom: -40px;
    margin-right: -48px;
    font-size: 72px;
  }

  &-image {
    margin-top: -11px;
  }

  &-word {
    font-size: 22px;
    margin-top: -8px;
    margin-left: 8px;
  }

  &-greeting {
    position: relative;
    width: 81%;
    height: 40px;
    align-self: center;
    margin: 14px 0;
    border: 1px solid #dcb05e;
    border-radius: 10px;
    box-shadow: inset 1px 1px 5px -1px rgba(0, 0, 0, 0.49);
    padding-left: 8px;
  }

  &-prev, &-next {
    position: absolute;
  }

  &-prevArrowIcon {
    background-image: url(../img/prevIcon.png);
    width: 31px;
    height: 31px;
    &:hover {
      background-image: url(../img/prevIconHover.png);
    }
  }

  &-prev {
    top: 39%;
    left: 0px;
    background: none;
    border: none;

    &:focus {
      outline: none;
    }
  }

  &-nextArrowIcon {
    background-image: url(../img/nextIcon.png);
    width: 31px;
    height: 31px;
    &:hover {
      background-image: url(../img/nextIconHover.png);
    }
  }

  &-next {
    top: 39%;
    right: 0px;
    background: none;
    border: none;

    &:focus {
      outline: none;
    }
  }

 }

@media(max-width: $medium-media) {
  .gifts {

    &-content{
      width: 95%;
    }

    &-title {
      margin-top: 0.5vw;
      background: #ed6b0b;
      width: 98%;
      height: 48px;
      justify-content: center;
    }

    &-titleText {
      font-size: 3vw;
      width: 98%;
    }

    &-buttonsBlock {
      flex-direction: column;
      align-items: center;
    }

    &-help {
      order: 2;
    }

    &-help, &-share {
      margin-bottom: 1.5vw;
      width: 48%;
      font-size: 2.5vw;
    }

    &-number {
      margin-bottom: -5.2vw;
      margin-right: -6.3vw;
      font-size: 9.4vw;
    }

    &-word {
      font-size: 2.9vw;
      margin-top: -1vw;
      margin-left: 1vw;
    }


    &-choose {
      padding: 0 2.1vw;
      .gifts-coinCount {
        margin-left: 6.4vw;
        width: 20.5%;
      }
      .gifts-coinsWrapper {
        .gifts-image {
          width: 100%;
        }
      }
    }

    &-button {
      margin: 3.6vw 0;
    }

  }
}

@media(max-width: $small-media) {
  .gifts {
    &-title {
      height: 8vw;
    }

    &-titleText {
      font-size: 5vw;
    }

    &-item {
      .gifts-label {
        .gifts-present {
          width: 25vw;
        }
      }
    }

    &-help, &-share {
      width: 73%;
      font-size: 4.5vw;
      height: auto;
    }

    &-number {
      font-size: 13.4vw;
      margin-bottom: -6.2vw;
      margin-right: -13.3vw;
    }

    &-word {
      font-size: 4.9vw;
      margin-top: -2vw;
    }

    .gifts-coinsWrapper {
      .gifts-image {
        width: 25vw;
      }
    }

    &-choose {
      margin-top: 12vw;
    }

    &-prevArrowIcon, &-nextArrowIcon {
      margin: 0 auto;
      width: 12vw;
      height: 12vw;
      background-size: cover;
    }

    &-next, &-prev {
      width: 22vw;
      height: 19vw;
      top: unset;
      bottom: 11%;
    }

    &-greeting {
      font-size: 4.5vw;
    }

    &-closeIcon {
      width: 5.2vw;
    }

    &-closeIconHov {
      width: 5.2vw;
    }
  }
}
