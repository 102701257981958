.myProfileHeader {
  z-index: 0;
  background-image: url(../img/bg--header.png);
  background-size: cover;
  font-size: 1.25rem;
  font-size: 1.041vw;

  &-slider {

  }

  &-wrapper {
    background-color: white;
  }

  &-upperRow {
    // background-color: #dcdcdc;
    height: 3.2vw;
    justify-content: space-between;
    // padding: 0vw 8vw;
    padding: 2.9vw 3.62vw
  }

  &-button{
    // padding: 0.2vw 2vw;
    // font-size: 1.2vw;
    padding: 0.32vw 2vw;
    font-size: 0.75vw;
    font-size: 0.75em;
    text-transform: uppercase;
  }

  &-blueButtonWrapper {
    // width: 24%;
  }

  &-logo{
    // width: 22vw;
    // width: 27.5vw;
    height: 2.55em;
  }

  &-logo-image {
    width: 100%;
    // min-height: 2rem;
    height: 100%;
    // min-height: 2rem;
  }

  &-bottom{
    // background-image: url(../img/header.jpg);
    background-size: cover;
    // height: 13.28vw;
    height: 10.8vw;
  }

  &-nameProfile {
    // color: $orangeTextColor;
    color: white;
    text-transform: uppercase;
    margin-right: 1vw;
    // font-size: 1.5vw;
    font-size: 1.3em;
    text-underline: none;
    max-width: 10vw;
  }

  &-profileRef {
    text-decoration: none;
  }

  &-messageButton {
    background: none;
    border: none;
  }

  &-icon{
    height: 1.3em;
    &:hover {
      color: $headerRefHoverColor;
      mix-blend-mode: color;
    }
  }

  &-msgCount {
    font-size: 1.3em;
    color: $headerMsgCountColor;
  }

  &-refButton {
    text-decoration: none;
    // color: $headerRefColor;
    &:hover {
      color: $headerRefHoverColor;
    }
  }

}

@media(max-width: $large-media) {
  .myProfileHeader {
    &-upperRow {
      min-height: 50px;
      background-position: center;
      padding: 0;

      height: 3.2vw;
      justify-content: space-between;
      // padding: 0vw 8vw;
      padding: 2.9vw 3.62vw
    }

    &-logo {
      width: 35vw;
      height: auto;
    }

    &-blueButtonWrapper {
      max-width: 38%;
    }

    &-nameProfile {
      // font-size: 2.5vw;
    }

    &-icon{
      height: 1em;
    }

    &-msgCount {
      font-size: 2.8vw;
    }

    &-button{
      // padding: 0px 14px;
      // font-size: 16px;

      padding: 0.32vw 2vw;
      font-size: 0.75vw;
    }
  }
}

@media(max-width: $medium-media) {
  .myProfileHeader {
    background-image: url(../img/m-bg--header.png);
    background-size: cover;

    // &-slider .owl-carousel {
    //   flex-grow: 1;
    //   width: 50%;
    // }

    &-slider .slider-changeRegionButtonImg, .slider-photo{
      min-height: 31vw;
    }

    &-slider .slider-photo,
    &-slider .slider .owl-item{
      width: 27vw!important;
    }

    &-upperRow {
      height: 42.6vw;
      align-items: start;
      position: relative;
      // padding: 2.9vw 3.62vw;
      padding: 2.5vw 5vw;
    }

    &-inTopPhotoHeader {
      position: absolute;
      width: 23%;
      right: 31.5vw;
      top: 12vw;
      z-index: 2;
    }

    &-buttonTop {
      // position: absolute;
      text-transform: uppercase;
      background-color: $btnBgColor4;
      color: $btnColor4;
      
      top: -4vw;
      padding: 1vw 0;
      font-size: 2.2vw;
      // width: 100%;

      padding: 1vw 1.4vw;
      line-height: 0.6em;
      font-size: 3.1em;
      height: 7vw;
      margin: 3vw 5.2vw;
      
    } 

    &-coronaInTop {
      position: absolute;
      bottom: 2vw;
      width: 46%;
      left: 1vw;
    }

    &-inTopPhoto {
      height: 26vw;
      width: 100%;
      border: 4px solid #ff9306;
      border-radius: 8px;
    }

    &-logo {
      width: 61.65%;
      height: auto;
      margin-top: 1vw;
    }

    &-blueButtonWrapper {
      // max-width: 38%;
      max-height: 30vw;
    }

    &-message {
      align-items: start;
    }

    &-nameProfile {
      // font-size: 1em;
      font-size: 7em;
      line-height: 1em;
    }

    &-bottom{
      // background-image: url(../img/myProfileBottomHeader-mobile.jpg);
      background-image: none;
      background-size: contain;
      height: 11.5vw;
      background-repeat: no-repeat;
    }

    &-icon{
      height: 7em;
    }

    &-messageButton {
      // margin-top: -0.5rem;
    }
  }
}

@media(max-width: $small-media) {
  .myProfileHeader {
    &-icon{
      // height: 2rem;
    }
    &-messageButton {
      // margin-top: -0.5rem;
    }
  }
}