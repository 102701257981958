@use 'sass:color';

.dialog {

  &-root {
    max-width: 100%;
  }

  &-content{
    width: 968px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &-title {
    // background: linear-gradient(90deg, rgba(151,87,150,1) 0%, rgba(254,87,205,1) 50%, rgba(151,87,150,1) 100%);
    background: $popupTitleBgColor;
    width: 95%;
    height: 32px;
    margin: 6px auto;
  }

  &-titleText {
    width: 96%;
    font-size: 20px;
  }

  &-close {
    background: none;
    border: none;
    &:hover {
      .dialog-closeIcon {
        display: none;
      }

      .dialog-closeIconHov {
        display: block;
      }
    }
  }

  &-closeIconHov {
    display: none;
  }

  &-main {
    width: 78%;
    padding: 0 12px;
  }

  &-messagesWrapper {
    // background-image: url(../img/dialogsBackground.jpg);
    background-color: #fbc59d;
    height: 544px;
    padding: 24px 32px;
    // border: 2px solid #d8bfd2;
    overflow-y: scroll;
  }

  &-bottomBar {
    border: 2px solid $dialogTxtInputBorderColor;
    border-radius: 10px;
    padding: 4px;
    margin-top: -16px;
    background: $white;
    z-index: 1;
  }

  &-gate {
    margin-top: 32px;
  }

  &-buttonsBlock {
    border: 1px solid $dialogTxtInputBorderColor;
    border-radius: 10px;
  }

  &-addSticker, &-addSmile, &-sendMessage {
    background: none;
    border: none;
  //   background-color: rgba(255, 255, 255, 1);
  // // background-image: url("yourimage");
  //   background-blend-mode: luminosity;
    transition: filter .3s ease-out;
    filter: blur(0) grayscale(0);
  }

  &-addSticker.not-allowed, &-addSmile.not-allowed, &-sendMessage.not-allowed {
    filter: blur(0.1em) grayscale(0.5);
  }

  &-addSticker.not-allowed:hover, &-addSmile.not-allowed:hover, &-sendMessage.not-allowed:hover{
    filter: blur(0) grayscale(0);
  }

  &-messagesSender {
    border: none;
    width: -webkit-fill-available;
    padding-left: 16px;
  }

  &-message {
    width: 88%;
    &-status img{
      height: 20px;
    }
  }

  &-avatar {
    width: 51px;
    height: 59px;
    border-radius: 10px;
    margin: 8px;
    object-fit: cover;
  }

  &-incoming {
    flex-direction: row-reverse;
  }

  &-name {
    font-size: 18px;
    color: $dialogNameColor;
  }
  &-name-none {
    font-style: italic;
    color: color.adjust($dialogNameColor, $lightness: +30%, $alpha: -0.4);
  }

  &-footer {
    margin-top: 8px;
    margin-bottom: 16px;
    width: 100%;
  }

  &-goldLine {
    margin-bottom: 16px;
    height: 3px;
    width: 90%;
    background: #dbc758;
  }

   &-help {
     width: 27%;
     font-size: 24px;
  }

  &-bottom {
    width: 100%;
  }

  &-logo {
    margin-left: 16px;
  }

  &-form {
    margin-bottom: 24px;
  }

  &-buttonUpload {
    display: none;
  }

  &-labelUpload {
    cursor: pointer;
  }

  &-smileClose {
    width: 99%;
  }

  &-uploadWrapper {
    position: relative;
    background: $white;
    width: 716px;
    padding: 24px 0;
  }

  &-stickersWrapper {
    position: relative;
    width: 608px;
    padding: 8px 16px;
    background: $white;
  }

  &-dropdownStickers {
    position: absolute;
    top: 0%;
    left: 19%;
  }

  &-itemsWrapper {
    flex-wrap: wrap;
    width: 100%;
    height: 504px;
    overflow-y: scroll;
  }

  &-item {
    width: 15%;
    margin: 8px;
  }

  &-picture {
    width: 100%;
  }

  &-containerPaid {
    flex-wrap: wrap;
  }

  &-info {
    padding: 4px 16px;
  }

  &-part {
    width: 50%;
  }

  &-price {
    width: 30%;
  }

  &-text {
    color: #6632c2;
    margin: 0 8px;
    text-align: center;
  }

  &-textextra-image {
    max-width: 20em;
  }

  &-addButton {
    background: none;
    border: 1px solid #dbc758;
    border-radius: 10px;
    padding: 4px;
  }

  &-itemsWrapperPaid {
    width: 100%;
    height: 504px;
    overflow-y: scroll;
  }

  &-smilesWrapper {
    position: relative;
    width: 100%;
    padding: 8px 16px;
    background: linear-gradient(45deg,
            rgba(235,224,209,1) 0%,
            rgba(255,255,229,1) 29%,
            rgba(236,225,210,1) 64%,
            rgba(253,253,227,1) 79%,
            rgba(255,255,229,1) 100%);
  }

  &-dropdownSmiles {
    position: absolute;
    top: 15%;
    left: 10%;
    width: 80%;
  }

  &-smilesItemsWrapper {
    flex-wrap: wrap;
  }

  &-smilesItem {
    font-size: 1.8em;
    width: 4.3%;
    margin: 8px;
  }

  &-picture {
    width: 100%;
  }

  &-goldLineSmiles {
    margin: 4px 0;
    height: 2px;
    width: 99%;
    background: #dbc758;
  }

  &-redDot {
    border-radius: 50%;
    width: 0.8vw;
    height: 0.8vw;
    background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(195,0,0,1) 100%);
    position: absolute;
    top: .4vw;
    right: .4vw;
  }

  &-greenDot {
    border-radius: 50%;
    width: 0.8vw;
    height: 0.8vw;
    background: linear-gradient(90deg, rgba(69,183,89,1) 0%, rgba(70,145,85,1) 100%);
    position: absolute;
    top: .4vw;
    right: .4vw;
  }

  &-grayDot {
    border-radius: 50%;
    width: 0.8vw;
    height: 0.8vw;
    background: linear-gradient(90deg, rgb(185, 187, 185) 0%, rgb(90, 90, 90) 100%);
    position: absolute;
    top: .4vw;
    right: .4vw;
  }

  &-inlineDot {
    width: 0.8vw;
    height: 0.8vw;
    position: relative;
    display: inline-block;
    top: .1vw;
  }
  &-wasOnline {
    font-size: 70%;
    margin: 0 .8vw;
    margin-top: 0.3vw;
  }
  &-giftsSelectHead {
    img {
      // height: 1.6vw;
      // width: 6.6vw;
      width: 2.4vw;
    }
    margin-top: -0.3vw;
  }

  &-opponentInfo-item{
    display: inline-block
  }

  &-messengerHead {
    margin-top: 0 !important;
  }

  &-messengerHead {
    margin-top: 0 !important;
  }

  &-modal {
    z-index: 1065;
    &-content {
      width: 344px;
    }

    &-spanForgotPass {
      margin-top: 40px;
      text-transform: uppercase;
      font-weight: 500;
    }

    &-spanText {
      text-align: center;
      font-size: 14px;
      width: 288px;
    }

    &-inputArea {
      width: 288px;
      height: 33px;
      margin-top: 20px;
    }

    &-button {
      font-size: 16px;
      width: 275px;
      margin-top: 22px;
      margin-bottom: 56px;
    }


  }
}

@media(max-width: $large-media) {
  .dialog {
    &-content {
      width: 98%;
    }

    &-infoWrapper {
      margin-top: 4.3vw;
    }

    &-info {
      font-size: 3vw;
    }

    &-iconWrapper {
      margin-top: 4.2vw;
    }

    &-footer {
      margin-top: 2.8vw;
      margin-bottom: 1.4vw;
    }

    &-goldLine {
      margin-bottom: 1.4vw;
    }

    &-help {
      font-size: 2.1vw;
    }

    &-logo {
      margin-left: 1.4vw;
      width: 28%;
    }

    &-dropdownUpload {
      width: 73%;
      background: none;
    }

    &-uploadWrapper {
      width: 100%;
    }

    &-gate {
      width: 10%;
    }

    &-door {
      width: 100%;
    }

    &-stickersWrapper {
      width: 100%;
    }

    &-dropdownStickers {
      width: 63%;
    }
  }
}

@media(max-width: $medium-media) {
  .dialog {

    &-title {
      height: 5vw;
    }

    &-titleText {
      font-size: 3vw;
    }

    &-footer {
      margin-top: 2vw;
      margin-bottom: 6vw;
    }

    &-topUpButton {
      width: 49%;
      font-size: 3.1vw;
      margin-bottom: 2.1vw;
    }

    &-topUp {
      flex-direction: column;
    }

    &-info {
      font-size: 4vw;
    }

    &-content {
      margin-top: 0;
    }

    &-help {
      font-size: 3.1vw;
      width: 41.8%;
    }

    &-messagesWrapper {
      background-image: none;
      border: none;
      padding: 0;
    }

    &-main {
      width: 100%;
    }

    &-avatar {
      width: 13.5%;
      height: 15vw;
      margin: 1.1vw;
    }

    &-name {
      font-size: 4vw;
    }

    &-opponentName {
      font-size: 1.2em;
      display: inline-block;
    }

    &-message {
      width: 100%;
      font-size: 2.5vw;
    }

    &-incoming, &-outgoing {
      width: 100%;
    }

    &-bottomBar {
      padding: 0.5vw;
      margin-top: 2vw;
    }

    &-messagesSender {
      padding-left: 2vw;
      font-size: 3vw;
    }

    &-plane {
      width: 5.7vw;
    }

    &-upload, &-smile {
      width: 4vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 4vw;
      height: 4vw;
    }

    &-dropdownUpload {
      width: 93%;
    }

    &-item {
      width: 22%;
      margin: 1vw;
    }

    &-smilesWrapper {
      background: $white;
    }

    &-smilesItemsWrapper {
      height: 56vw;
      overflow-y: scroll;
    }

    &-smilesItem {
      width: 7.3%;
      margin: 2vw;
    }
    &-giftsSelectHead {
      img {
        // height: 1.6vw;
        width: 3.6vw;
        // width: 2.4vw;
      }
      // margin-top: -0.3vw;
    }
  }
}

@media(max-width: $small-media) {
  .dialog {
    &-title {
      height: 8vw;
    }

    &-titleText {
      font-size: 5vw;
    }

    &-plane {
      width: 8.7vw;
    }

    &-upload, &-smile {
      width: 6vw;
    }

    &-message {
      font-size: 4vw;
    }

    &-avatar {
      width: 17.5%;
      height: 17vw;
    }

    &-messagesSender {
      font-size: 3vw;
      padding-left: 1vw;
    }

    &-help {
      font-size: 5.1vw;
      width: 55.8%;
    }

    &-closeIcon, &-closeIconHov {
      width: 5vw;
      height: 5vw;
    }

    &-dropdownUpload {
      width: 87%;
    }

    &-dropdownStickers {
      width: 90%;
      top: 3%;
      left: 4%;
    }

    &-item {
      width: 30%;
    }

    &-itemPaid {
      width: 31%;
      margin: 1vw;
    }

    &-text {
      margin: 0 2vw;
      font-size: 4vw;
    }

    &-smilesItem {
      width: 11.3%;
    }

    &-smilesItemsWrapper {
      height: 58vw;
    }

    &-dropdownSmiles {
      position: absolute;
      top: 45%;
    }

    &-info {
      padding: 0;
      margin: 4vw 1vw;
    }
    &-giftsSelectHead {
      img {
        // height: 1.6vw;
        width: 6.6vw;
        // width: 2.4vw;
      }
      // margin-top: -0.3vw;
    }
  }
}

