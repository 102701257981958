@import "./colors.scss";


$small-media: 540px;
$medium-media: 769px;
$large-media: 1140px;
$extraLarge-media: 1550px;
// $iconCheckbox: "%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%236201c4' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e";
$iconCheckbox: "%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fbc59d' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e";
// $baseBtnBorderThin: 2px;
$baseBorderThin: 0.1vw;
$baseBtnBorderThin: $baseBorderThin;
$topCountryAvatarBorderThin: $baseBorderThin;



// %baseBtn {
//   background: $btnBgColor1;
//   border: $baseBtnBorderThin solid $btnBgColor1;
//   border-radius: 0.7vw;
//   color: $btnColor1;
//   cursor: pointer;
//   &:hover {
//     background: $btnColor1;
//     color: $btnBgColor1;
//   }
// }


%baseBoxShadow1 {
  box-shadow: 0 0.1vw 0.5vw rgba(#000000, 0.3);
}

%baseBoxShadow {
  @extend %baseBoxShadow1;
}

@mixin baseBtnShadow($x: 0, $y: 0.1vw, $r: .5vw) {
  @extend %baseBoxShadow1;

  &:hover{
    box-shadow: $x $y $r rgba(#000000, 0.5);
  }
  &--active {
    box-shadow: $x $y $r rgba(#000000, 0.4);
  }
}


@function fibonacci($n) {
  $sequence: 0 1;
  @for $_ from 1 through $n {
    $new: nth($sequence, length($sequence)) + nth($sequence, length($sequence) - 1);
    $sequence: append($sequence, $new);
  }
  @return nth($sequence, length($sequence));
}

.flex {
  display: flex;
}

.block {
  display: block;
}

.display-none {
  display: none;
}

.a-i-center {
  align-items: center;
}

.a-i-flex-end {
  align-items: flex-end;
}

.a-s-center {
  align-self: center;
}

.a-s-flex-end {
  align-self: flex-end;
}

.j-c-center {
  justify-content: center;
}

.flex-n-w {
  flex-wrap: nowrap;
}

.flex-d-column {
  flex-direction: column;
}

.flex-d-col-reverse {
  flex-direction: column-reverse;
}

.j-c-s-around {
  justify-content: space-around;
}

.j-c-s-between {
  justify-content: space-between;
}

.j-c-s-evenly {
  justify-content: space-evenly;
}

.j-c-flex-end {
  justify-content: flex-end;
}

.j-c-flex-start {
  justify-content: flex-start;
}

.a-s-flex-start {
  align-self: flex-start;
}

.box-shadow-g-button {
  box-shadow: -3px 2px 2px 0px #cccccc;
}

.text-a-center {
  text-align: center;
}

.text-a-start {
  text-align: start;
}

.text-a-end {
  text-align: end;
}

.letter-spacing-1px {
  letter-spacing: 1px;
}

.text-purple {
  color: #712f9e;
}

.text-white {
  color: white;
}

.text-white-w-o-important {
  color: $white;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-bold-600 {
  font-weight: 600;
}

.text-bold-700 {
  font-weight: 700;
}

.text-bold-800 {
  font-weight: 800;
}

.text-bold-900 {
  font-weight: 900;
}

.line-height-1 {
  line-height: 1;
}

.text-orange {
  color: #f48400;
}

.dropdownPurple {
  // border: 2px solid #ae60b8;
  background: #fbc59d;
  border: 2px solid white;
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 16px;
  margin-left: -4px !important;
}

.dropdownInput {
  // border: 2px solid #ae60b8;
  border: 2px solid white;
  border-radius: 10px;
  padding: 2px 8px;
  margin: 0px 4px;
  box-shadow: inset 3px 3px 3px 0px rgba(160,112,156,0.8);
}

.dropdownEl {
  margin: 4px;
}

.gradientBorder:before {
  bottom: -7px;
  background: linear-gradient(0deg,#9d6418 0,#f3ec72 46%,#dcb05e 100%);
  top: -7px;
  left: -7px;
  right: -7px;
  content: "";
  position: absolute;
  border-radius: 10px;
  z-index: -1;
}

.checkboxPurple {
  border: 2px solid #ae60b8;
  box-shadow: inset 3px 3px 3px 0px rgba(160,112,156,0.8);
}


@media(min-width: $medium-media) {
  .hide-large {
    display: none;
  }
}

@media(min-width: $small-media) and (max-width: $medium-media) {
  .hide-medium {
    display: none;
  }
}

@media(max-width: $small-media) {
  .hide-small {
    display: none;
  }
}
