.topUpBalance {

  &-root {
    max-width: 100%;
  }

  &-main {
    padding-top: 1.4vw;
  }

  &-content{
    width: 968px;
  }

  &-title {
    width: 95%;
    background: $popupTitleBgColor;
    height: 32px;
    margin: 6px auto;
  }

  &-titleText {
    width: 96%;
    font-size: 20px;
  }

  &-close {
    background: none;
    border: none;
    &:hover {
      .topUpBalance-closeIcon {
        display: none;
      }

      .topUpBalance-closeIconHov {
        display: block;
      }
    }
  }

  &-closeIconHov {
    display: none;
  }

  &-balance {
    font-size: 56px;
    letter-spacing: 3px;
  }

  &-yourBalance {
    margin-bottom: 24px;
  }

  &-holder {
    padding: 32px 8px;
    font-size: 58px;
    letter-spacing: 2px;
    border: 2px solid #dcb05e;
    border-radius: 10px;
    box-shadow: inset 1px 1px 5px -1px rgba(0,0,0,0.49);
    &:focus {
      outline: none;
    }
  }

  &-topUpButton {
    // border: 3px solid #dbc758;
    &:hover {
      color: $black;
      background: #7dbae4;
      box-shadow: none;
    }
  }

  &-howMuch {
    width: 53%;
    margin: 8px 8px 0px 8px;
    font-size: 19px;
    padding: 8px;
    border: 2px solid #dcb05e;
    border-radius: 10px;
    box-shadow: inset 1px 1px 5px -1px rgba(0,0,0,0.49);
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #cccccc;
      font-weight: 200;
    }
  }

  &-topUp {
    margin-bottom: 24px;
  }

  &-iconWrapper {
    width: 100%;
    margin-top: 48px;
  }

  &-icon {
    width: 25%;
  }

  &-merge {
    margin-left: 16px;
    width: 50%;
  }

  &-infoWrapper {
    margin-top: 48px;
  }

  &-info {
    font-size: 34px;
  }

  &-supplement, &-monet, &-for {
    font-size: 43px;
    letter-spacing: 2px;
  }

  &-footer {
    margin-top: 32px;
    width: 100%;
    margin-bottom: 16px;
  }

  &-topUpButton {
    width: 36%;
    font-size: 27px;
    margin-bottom: 24px;
  }

  &-goldLine {
    margin-bottom: 16px;
    height: 3px;
    width: 90%;
    background: #dbc758;
  }

   &-help {
     width: 27%;
     font-size: 24px;
  }

  &-bottom {
    width: 100%;
  }

  &-logo {
    margin-left: 16px;
  }

}

@media(max-width: $large-media) {
  .topUpBalance {
    &-content {
      width: 98%;
    }

    &-yourBalance {
      margin-bottom: 2.1vw;
    }

    &-balance {
      font-size: 5vw;
    }

    &-holder {
      font-size: 5vw;
      padding: 4.2vw 1vw;
    }

    &-topUp {
      margin-bottom: 2.1vw;
    }

    &-infoWrapper {
      margin-top: 4.3vw;
    }

    &-supplement, &-monet, &-for {
      font-size: 3.8vw;
    }

    &-howMuch {
      font-size: 1.6vw;
      height: 4vw;
    }

    &-info {
      font-size: 3vw;
    }

    &-iconWrapper {
      margin-top: 4.2vw;
    }

    &-footer {
      margin-top: 2.8vw;
      margin-bottom: 1.4vw;
    }

    &-topUpButton {
      font-size: 2.4vw;
      margin-bottom: 2.1vw;
    }

    &-goldLine {
      margin-bottom: 1.4vw;
    }

    &-help {
      font-size: 2.1vw;
    }

    &-logo {
      margin-left: 1.4vw;
      width: 28%;
    }

  }
}

@media(max-width: $medium-media) {
  .topUpBalance {
    // &-content {
    //   background-image: url(../img/upInSearchBckgrnMobile.jpg);
    //   background-size: contain;
    // }

    &-title {
      // background: linear-gradient(90deg, rgba(151,87,150,1) 0%, rgba(254,87,205,1) 50%, rgba(151,87,150,1) 100%);
      background: #ed6b0b;
      height: 5vw;
    }

    &-titleText {
      font-size: 3vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 4vw;
      height: 4vw;
    }

    &-footer {
      margin-top: 2vw;
      margin-bottom: 6vw;
    }

    &-topUpButton {
      width: 49%;
      font-size: 3.1vw;
      margin-bottom: 2.1vw;
    }

    &-topUp {
      flex-direction: column;
    }

    &-merge {
      width: 71%;
      align-items: flex-start;
      margin: 0;
    }

    &-howMuch {
      margin: 1.1vw 1.1vw 0;
      width: 55%;
      font-size: 4vw;
      height: 12vw;
    }

    &-info {
      font-size: 4vw;
    }

    &-help {
      font-size: 3.1vw;
      width: 41.8%;
    }

    &-monet, &-for {
      font-size: 4.8vw;
    }

    &-supplement {
      margin-right: 7vw;
      font-size: 4.8vw;
    }
  }
}

@media(max-width: $small-media) {
  .topUpBalance {

    &-title {
      height: 8vw;
    }

    &-titleText {
      font-size: 5vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 5vw;
      height: 5vw;
    }

    &-howMuch, &-holder {
      border-radius: 2vw;
    }
  }
}

