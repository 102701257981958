
.main {
  background-size: cover;
  z-index: 1;
  height: 40.58vw;
  margin-top: -3.52vw;
  
  display: flex;
  width: calc(100vw - (100vw - 100%));
  // flex-grow: 1;
  // align-items: stretch;
  // flex-direction: column;

  &-wrapper {
    background-color: white;
  }

  &-text {
    color: #6201c4;
    text-align: center;
    font-weight: 700;
    font-size: 1.2vw;
    width: 28vw;
    margin-top: 4vw;
  }

  &-span {
    text-transform: uppercase;
    font-size: 1.6vw;
  }

  &-warningText {
    color: #a16a1c;
    text-align: center;
    font-size: 0.88vw;
    width: 23vw;
    margin-top: 8.2vw;
    margin-top: 2.2vw;
  }

  &-warningSpan {
    text-transform: uppercase;
    font-size: 1.2vw;
  }

  &-buttonVideoHelp {
    font-size: 0.88vw;
    padding: .2vw 2vw;
  }

  &-helpButton {
    margin-top: 1.5vw;
  }

  &-bg-video {
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: -1;
  }
  &-bg-video-credits {
    color: gray; 
    text-align: center;
    a {
      text-decoration: none;
      color: gray;
      font-style: italic;
      :visited {
        color: gray;
      }
    }
  }
}

@media(max-width: $extraLarge-media) {
  .main {
    min-height: 630px;
    background-position: center;
    margin-top: -54px;

    &-refs {
      min-height: 23px;
      background-position: center;
    }

    &-span {
      font-size: 24px;
    }

    &-warningSpan {
      font-size: 18px;
    }

    &-warningText {
      line-height: 1.1;
      // margin-top: 208px;
      margin-top: 100px;
      margin-bottom: 16px;
      font-size: 16px;
      width: 352px;
      padding: 0;
    }

    &-helpButton {
        margin-top: 8px;
      }

    &-text {
      margin-top: 72px;
      font-size: 16px;
      width: 440px;
      padding: 0;
    }

    &-buttonVideoHelp {
      font-size: 14px;
      width: 160px;
      height: 32px;
      padding: 0;
    }

  }
}



.main-warningTextFrame {
  display: flex;
  flex-direction: column;
  align-items: center;

  $border: 7px;
  background: white;
  border-radius: 1em;

  border-width: $border;
  border-style: solid;
  border-image: linear-gradient(0deg, rgba(159,102,26,1) 0%, rgba(243,235,114,1) 50%, rgba(221,177,95,1) 100%) 1;
}

@media(max-width: $small-media) {
  .main {
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 152vw;
    margin-top: -12vw;

    &-refs {
      min-height: 23px;
      background-position: center;
    }

    &-warningText {
      display: none;
    }

    &-text {
      display: none;
    }

    &-buttonVideoHelp {
      font-size: 4vw;
      width: 53vw;
      height: 8vw;
      padding: 0;
    }

    &-blueButtonWrapper {
      margin-top: 12vw;
    }

    &-helpButton {
      margin-top: 88vw;
    }

    &-button {
      font-size: 4vw;
      padding: 2px 20px;
      margin: 1vw 0;
    }
  }
}
