.placeProfileInTop {

  &-root {
    max-width: 100%;
    left: -26.4vw;
  }

  &-content{
    // background-image: url(../img/profileInTopBackgroud.jpg);
    width: 600px;
  }

  &-wrapper {
    width: 100%;
  }

  &-close {
    background: none;
    border: none;
    &:hover {
      .placeProfileInTop-closeIcon {
        display: none;
      }

      .placeProfileInTop-closeIconHov {
        display: block;
      }
    }
  }

  &-closeIconHov {
    display: none;
  }

  &-title {
    background: $popupTitleBgColor;
    width: 95%;
    height: 32px;
    margin: 6px auto;
  }

  &-titleText {
    width: 96%;
    font-size: 20px;
  }

  // &-description {
    
  // }

  &-button {
    // background: $white;
    position: relative;
    width: 312px;
    height: 44px;
    margin: 28px 0;
  }

  &-coinCount {
    position: absolute;
    top: -48px;
    right: -56px;
  }

  &-coinCount2 {
    position: absolute;
    top: -47px;
    right: -64px;
  }

  &-coinCount3 {
    position: absolute;
    top: -53px;
    right: -70px;
  }

  &-coinCount4 {
    position: absolute;
    top: -53px;
    right: -70px;
  }

  &-image {
    margin-right: -14px;
    margin-top: -24px;
  }

  &-image2 {
    margin-right: -26px;
    margin-top: -16px;
  }

  &-image3 {
    margin-top: -11px;
  }

  &-image4 {
    margin-left: -75px;
    margin-top: -27px;
  }

  &-coinWrapper {
    display: flex;
    align-items: flex-end;
  }


  &-number {
    font-size: 72px;
    color: $contentOrderPriceColor;
  }

  &-number2 {
    font-size: 72px;
    margin-right: -140px;
  }

  &-number3 {
    font-size: 71px;
    margin-right: -58px;
    display: block;
  }

  &-word {
    font-size: 22px;
    margin-top: -9px;
    color: $contentOrderPriceColor;
  }

  &-word2 {
    font-size: 22px;
    margin-right: 40px;
    margin-top: -8px;
  }

  &-word3 {
    font-size: 22px;
    margin-right: 40px;
    margin-top: -11px;
  }

  &-menu {
    margin-top: 24px;
    margin-left: 16px;
    width: 71%;
  }

  &-brownLine {
    background: #ed6b0b;
    height: 3px;
    width: 92%;
    margin: 2% auto;
  }

  &-inTopWrapper {
    width: 28%;
  }

  &-viewOther {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &-viewTop {
    margin-top: 8px;
  }

  &-inTopPhotoWrapper {
    margin-top: 0.3vw;
    // border: 5px solid $orangeBackground;
    // background-color: $orangeBackground;;
    border-radius: 10px;
    position: relative;
    margin-left: 12px;
    margin-top: 22px;
    width: 92%;
  }

  &-buttonLoupe {
    width: 100px;
    height: 50px;
    position: relative;
    border: 4px solid #dbc758;
    border-radius: 4px;
    color: #f48400;
    &:hover {
      background: #7dbae4;
    }
  }

  &-loupe {
    position: absolute;
    top: -8px;
    right: 16px;
    width: 60%;
  }

  &-inTopPhoto {
    width: 100%;
    border-radius: 1rem;
  }

  &-coronaInTop {
    position: absolute;
    width: 25%;
  }

  &-howLong {
    display: none;
    font-size: 18px;
  }

  &-button {
    &:hover {
      .placeProfileInTop-buttonText {
        display: none;
      }

      .placeProfileInTop-howLong {
        display: block;
      }
    }
  }

  &-buttons {
    padding: 0px 8px;
  }

  &-buttonsBlock {
    width: 100%;
    margin-bottom: 8px;
  }

  &-help, &-share {
    width: 48%;
    height: 38px;
    font-size: 19px;
  }

  &-share {
    // border: 3px solid #dbc758;
    &:hover {
      color: $black;
      background: #7dbae4;
    }
  }

  &-greeting {
    position: relative;
    width: 81%;
    height: 40px;
    align-self: flex-end;
    margin: 14px 0;
    border: 1px solid #dcb05e;
    border-radius: 10px;
    box-shadow: inset 1px 1px 5px -1px rgba(0,0,0,0.49);
    padding-left: 8px;

    &:focus {
      outline: none;
    }
  }

 }

@media(max-width: $medium-media) {
  .placeProfileInTop {
    &-root {
      left: 0;
    }

    &-content{
      // background-image: url(../img/profileInTopBackgrMobile.jpg);
      // background-position: center;
      width: 95%;
    }

    &-blueLine {
      background: $blue;
    }

    &-title {
      margin: 6px 3px;
    }

    &-titleText {
      background: none;
      margin-right: 0;
    }

    &-closeIcon, &-closeIconHov {
      width: 4vw;
      height: 4vw;
    }

    &-titleTextBottom {
      font-size: 4.2vw;
      width: 100%;
    }

    &-menu {
      width: 100%;
      margin-left: 0;
      margin-top: 5px;
      margin-bottom: 30px;
    }

    &-button {
      margin: 35px auto;
      width: 71%;
      height: 53px;
    }

    &-greeting {
      margin: 24px auto 0;
      width: 97%;
      height: 45px;
      font-size: 21px;
    }

    &-brownLine {
      height: 4px;
      width: 93%;
      background: #dbc758;
      margin: 0 auto;
    }

    &-buttonsBlock {
      flex-direction: column-reverse;
    }

    &-help, &-share {
      margin: 8px auto;
      font-size: 24px;
    }

    &-share {
      width: 71%;
      height: 56px;
    }

    &-help {
      width: 65%;
      height: 48px;
    }

    &-titleText {
      width: 100%;
      font-size: 4.2vw;
    }

    &-buttonText {
      // color: $black;
      font-size: 3.7vw;
      letter-spacing: 0px;
    }

    &-buttonLoupe {
      width: 22%;
      height: 9vw;
    }

    &-loupe {
      position: absolute;
      top: -1.1vw;
      right: 2vw;
    }

    &-viewTop {
      width: 50%;
      font-size: 3vw;
    }

    &-number {
      font-size: 9.4vw;
    }

    &-image {
      margin-right: -1.8vw;
      margin-top: -3.2vw;
    }

    &-word {
      font-size: 2.9vw;
      margin-top: -1.2vw;
    }

    &-number2 {
      font-size: 9.4vw;
      margin-right: -18.3vw;
    }

    &-word2 {
      font-size: 2.9vw;
      margin-right: 5.2vw;
      margin-top: -1.1vw;
    }

    &-image2 {
      margin-right: -3.4vw;
      margin-top: -2.1vw;
    }

    &-word3 {
      font-size: 2.9vw;
      margin-right: 5.2vw;
      margin-top: -1.5vw;
    }

    &-image3 {
      margin-top: -1.4vw;
    }

    &-image4 {
      margin-top: -3.5vw;
      margin-left: -9.8vw;
    }

    &-number3 {
      font-size: 9.4vw;
      margin-right: -7.5vw;
    }

    &-coinCount {
      top: -6.3vw;
      right: -7.3vw;
    }

    &-coinCount2 {
      top: -6.1vw;
      right: -8.3vw;
    }

    &-coinCount3 {
      top: -7vw;
      right: -9.1vw;
    }

    &-coinCount4 {
      top: -7vw;
      right: -9.1vw;
    }

    &-picture1 {
      width: 6.1vw;
    }

    &-picture2 {
      width: 7.9vw;
    }

    &-picture3 {
      width: 7.7vw;
    }

    &-picture4 {
      width: 8.6vw;
    }

    &-picture5 {
      width: 17vw;
    }

    &-picture6 {
      width: 17.1vw;
    }


  }
}

@media(max-width: $small-media) {
  .placeProfileInTop {

    &-content {
      width: 96.3%;
    }

    &-title {
      margin-bottom: 24px;
    }

    &-buttonText {
      display:block;
      overflow-wrap: break-word;
      font-size: 4.5vw;
    }

    &-help, &-share {
      font-size: 5vw;
      width: 70%;
      height: auto;
    }

    &-greeting {
      font-size: 5.3vw;
    }

    &-button {
      margin-top: 8px;
      margin-left: 8px;
      padding: 8px 18vw 8px 8px;
      width: 90%;
      height: auto;
    }

    &-number {
      font-size: 14vw;
    }

    &-number2 {
      font-size: 14vw;
      margin-right: -26vw;
    }

    &-image {
      margin-right: -2.6vw;
      margin-top: -3vw;
      width: 11vw;
    }

    &-image2 {
      margin-right: -4.8vw;
      margin-top: -3vw;
      width: 12vw;
    }

    &-image3 {
      margin-right: -15vw;
      margin-top: 1.8vw;
      width: 25vw;
    }

    &-image4 {
      margin-left: -3.8vw;
      width: 25vw;
    }

    &-word {
      margin-top: -1.7vw;
      font-size: 5vw;
    }

    &-word2 {
      font-size: 5vw;
      margin-right: 7.4vw;
      margin-top: -1.5vw;
    }

    &-coinCount {
      top: -9vw;
      right: -4vw;
    }

    &-coinCount2 {
      top: -8.8vw;
      right: -3.5vw;
    }

    &-number3 {
      font-size: 12vw;
    }

    &-word3 {
      font-size: 5vw;
    }

    &-coinCount3 {
      top: -9vw;
      right: -1.1vw;
    }

    &-coinCount4 {
      top: -9vw;
      right: -1.1vw;
    }

    &-viewTop {
      font-size: 4vw;
    }

    &-buttonLoupe {
      height: 12vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 5vw;
      height: 5vw;
    }
  }
}