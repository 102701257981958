.activateVip {

  &-root {
    max-width: 100%;
  }

  &-content {
    // background-image: url(../img/activateVip.jpg);
    // background-position: center;
    width: 968px;
  }

  &-mainWrapper {
    width: 100%;
  }

  &-main {
    width: 100%;
  }

  &-columns {
    padding-top: 32px;
    width: 760px;
    width: 100%;
  }

  &-left, &-middle, &-right {
    width: 25%;
  }

  &-under {
    margin-bottom: 24px;
  }

  &-image {
    width: 156px;
  }

  &-amount {
    font-size: 68px;
    font-weight: 400;
  }

  &-amountType {
    font-size: 33px;
  }

  &-icon {
    // margin-top: 8px;
    align-self: flex-end;
    // max-width: 7em;
    // margin-bottom: -2.3em;
    max-width: 178px;
    margin-bottom: -32px;
    min-height: 85px;
    object-fit: contain;
    margin-top: 21px;
    margin-right: 9%;
  }

  &-right &-icon {
    width: 111px;
    object-position: bottom;
  }

  &-buy {
    width: 160px;
    font-size: 24px;
    line-height: 1.2;
    padding-bottom: 4px;
  }

  &-price {
    font-size: 19px;
    margin-bottom: 8px;
  }

   &-aboutVip {
     width: 100%;
     margin-top: 16px;
   }

   &-whatIsVip {
    //  font-size: 46px;
     font-size: 2.2em;
     color: $contentOrderVipTitleColor;
   }

   &-head {
     font-size: 25px;
     margin-bottom: 8px;
     color: $contentOrderVipItemHeadColor;
   }

  &-title {
    background: $popupTitleBgColor;
    width: 97%;
    width: calc(100% - 0.7em);
    height: 32px;
    margin: 1vw auto .4vw auto;
  }

  &-titleText {
    width: 96%;
    font-size: 20px;
    font-size: 1rem;
  }

  &-close {
    background: none;
    border: none;
    &:hover {
      .activateVip-closeIcon {
        display: none;
      }

      .activateVip-closeIconHov {
        display: block;
      }
    }
  }

  &-closeIconHov {
    display: none;
  }

  &-activate {
    // border: 3px solid #dbc758;
    margin-bottom: 32px;
    // width: 336px;
    // &:hover {
    //   color: $black;
    //   background: #7dbae4;
    //   box-shadow: none;
    // }
  }

  &-textWrapper {
    margin-bottom: -24px;
  }

  &-footer {
    width: 100%;
    margin-bottom: 16px;
    margin-bottom: 19vw;
  }

  &-goldLine {
    margin-bottom: 16px;
    height: 3px;
    width: 90%;
    background: #dbc758;
  }

  // &-help {
  //   width: 280px;
  // }

//  &-activate, &-help {
//     font-size: 24px;
//   }

  &-bottom {
    width: 100%;
  }

  &-logo {
    margin-left: 16px;
  }

  &-carousel {
    position: relative;
    width: 82%;

    .activateVip-item img {
      width: 150px;
      width: 139px;
      margin: 0 auto;
    }

  }

  &-text {
    line-height: 1.2;
    color: $contentOrderVipItemHeadColor;
  }

  &-prev, &-next {
    position: absolute;
  }

  &-prevArrowIcon {
    background-image: url(../img/prevIcon.png);
    width: 31px;
    height: 31px;
    &:hover {
      background-image: url(../img/prevIconHover.png);
    }
  }

  &-prev {
    top: 64px;
    left: -64px;
    background: none;
    border: none;

    &:focus {
      outline: none;
    }
  }

  &-nextArrowIcon {
    background-image: url(../img/nextIcon.png);
    width: 31px;
    height: 31px;
    &:hover {
      background-image: url(../img/nextIconHover.png);
    }
  }

  &-next {
    top: 64px;
    right: -64px;
    background: none;
    border: none;

    &:focus {
      outline: none;
    }
  }

  &-optionShortDescr{
    @include coinShortDescr;
    // background: $contentOrderVipOptionDescrColor;
    // border-radius: .4em;
    // padding: .3em 1em;
  }

}

@media(max-width: $large-media) {
    .activateVip {

      &-content {
        width: 99%;
      }

      &-main {
        margin-top: 1vw;
      }

      &-image {
        width: 13.7vw;
      }

      &-head {
        font-size: 2.4vw;
      }

      &-text {
        font-size: 1.7vw;
      }

      &-invisible, &-moreMessages, &-moreKnow {
        width: 30%;
      }

      // &-help {
      //   font-size: 2.1vw;
      // }

      &-activate {
        margin-bottom: 2.8vw;
        // width: 34.5%;
        // font-size: 2.1vw;
      }
  }
}

@media(max-width: $medium-media) {
  .activateVip {

    // &-content {
    //   background-image: url(../img/profileInTopBackgrMobile.jpg);
    //   background-position: center;
    // }

    &-title {
      height: 48px;
      // background: linear-gradient(90deg, rgba(151,87,150,1) 0%, rgba(254,87,205,1) 50%, rgba(151,87,150,1) 100%);
      background: $popupTitleBgColor;
    }

    &-title {
      height: 5vw;
    }

    &-titleText {
      font-size: 3vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 4vw;
      height: 4vw;
    }

    &-aboutVip {
      flex-direction: column;
      align-items: center;
    }

    &-whatIsVip {
      font-size: 8vw;
    }

    &-image {
      width: 21.7vw;
    }

    &-head {
      font-size: 4.4vw;
    }

    &-text {
      font-size: 2.3vw;
      margin-top: -1vw;
    }

    &-moreKnow, &-moreMessages, &-invisible {
      width: 80%;
      margin-bottom: 4vw;
    }

    &-scrollWrapper {
      margin-top: 6vw;
      margin-bottom: 6vw;
    }

    &-scrollButtonHover {
      display: none;
      // blend-mode
    }

    &-scroll {
      background: none;
      border: none;

      &:focus {
        outline: none;
      }

      &:hover {
        .activateVip-scrollButton {
          display: none;
        }

        .activateVip-scrollButtonHover {
          display: block;
        }
      }
    }

    &-main {
      width: 100%;
      flex-direction: column;
      align-items: center;
      // height: 93vw;
      margin-top: -3vw;

    }

    &-next {
      top: 8.3vw;
      right: -8.3vw;
    }

    &-prev {
      top: 8.3vw;
      left: -8.3vw;
    }

    &-icon {
      margin-right: -2%;
    }

    &-carousel {
      .activateVip-item img {
        width: 100%;
      }
    }
    // &-carousel .active:nth-child(2) {
    //   background: green;
    // }
    &-carousel .owl-item &-item {
      // padding-top: 20vw;
      transition: padding-top 0.3s;
    }
    &-carousel .owl-item:not(.active-pop) &-item {
      // background: green;
      // height: 175%;
      padding-top: 50%;
    }

    &-columns {
      flex-direction: column;
      width: auto;
      padding-top: 0;
    }

    &-left, &-middle, &-right{
      width: auto;
    }

    &-head, &-text {
      // width: 130%;
    }

  }
}

@media(max-width: $small-media) {
  .activateVip {

    &-nextArrowIcon {
      background-size: contain;
      width: 40px;
      height: 40px;
    }

    &-prevArrowIcon {
      background-size: contain;
      width: 40px;
      height: 40px;
    }

    &-title {
      height: 8vw;
    }

    &-titleText {
      font-size: 5vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 5vw;
      height: 5vw;
    }
  }
}
