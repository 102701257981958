.authModal{
  &-content {
    width: 328px;
  }

  &-inputBlock {
    width: 80%;
    margin-top: 24px;
  }

  &-loginText {
    margin-right: 32px;
  }

  &-passText {
    margin-right: 24px;
  }

  &-brownLine {
    background: #ed6b0b;
    height: 4px;
    width: 288px;
    margin-top: 16px;
  }

  &-preInputsText {
    color: $orangeTextColor;
  }

  &-inputCheckbox {
    margin-top: 8px;
    width: 77%;
    color: $orangeTextColor;
  }

  &-checkbox {
    margin-left: 8px;
  }

  &-inputArea {
    width: 176px;
    height: 33px;
  }

  &-button {
    margin-top: 16px;
    width: 264px;
  }

  &-withSocialNetwork {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  &-loginWithSocNet {
    color: $orangeTextColor;
    font-size: 16px;
  }
  &-ref:hover {
    transform: scale(0.8);
  }

 }

