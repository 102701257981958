.multiselect__content-wrapper {
  overflow-y: scroll;
  overflow-x: visible;
}

.multiselect__tag {
  background-color: #777;
}

.multiselect__tag-icon::after {
  color: #ae60b8;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: linear-gradient(#ae60b8, #ae60b8);
}

.multiselect__option--highlight {
  background: linear-gradient(#ae60b8, #ae60b8);
}

.multiselect__option--highlight::after {
  content: attr(data-select);
  background: linear-gradient(#ae60b8, #ae60b8);
  color: white;
  display: none;
  position: relative;
}

.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: bold;
}

.multiselect__option--selected::after {
  content: attr(data-selected);
  color: silver;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: linear-gradient(#ae60b8, #ae60b8);
  color: #fff;
}

.multiselect__option--selected.multiselect__option--highlight::after {
  background: linear-gradient(#ae60b8, #ae60b8);
  content: attr(data-deselect);
  color: #fff;
}

.no-background {
  background: none !important;
}

/* подсветка важных целей на сайте */
.c-f-partner--purpose .c-v-90 label,
.c-f-partner--purpose .c-v-100 label, 
.c-f-partner--purpose .c-v-110 label{
  color: #ae60b8;
}