#header-index {
  background: none;
}

.header {
  z-index: 0;

  &-wrapper {
    background-color: white;
  }

  &-upperRow {
    background-color: #dcdcdc;
    height: 3.2vw;
    justify-content: space-between;
    padding: 0vw 8vw;
  }

  &-button {
    padding: 0.2vw 2vw;
    font-size: 1.2vw;
  }

  &-image {
    width: 87%;
  }

  &-bottom {
    background-image: url(../img/header.jpg);
    background-size: cover;
    height: 13.28vw;
  }
}

@media(max-width: $extraLarge-media) {
  .header {
    &-upperRow {
      min-height: 50px;
      background-position: center;
      padding: 0 4vw;
    }
    &-bottom{
      background-image: url(../img/header.jpg);
      background-size: cover;
      min-height: 205px;
      background-position: center;
    }
    &-image{
      width: 336px;
    }
    &-button{
      padding: 2px 32px;
      font-size: 20px;
    }
  }
}

@media(max-width: $medium-media) {
  .header {
    &-upperRow {
      padding: 0;
    }
    &-button{
      padding: 0px 14px;
      font-size: 16px;
    }
  }
}

@media(max-width: $small-media) {
  .header {
    &-upperRow{
      justify-content: center;
    }
    &-image{
      width: 87%;
    }
    &-bottom{
      // background-image: url(../img/myProfileBottomHeader-mobile.jpg);
      // background-size: contain;
      min-height: 43.5vw;
      // height: 11.5vw;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
