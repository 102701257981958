@use 'sass:color';

$nav-font-color: #ccc8c8;
$orangeTextColor: #f48400;
$orangeBackground: #ff9306;
$white: #ffffff;
$black: #000000;
$blue: #5a91e2;


$baseColor1: #00a0e3;
$baseColor2: #ed6b0b;
$baseColor3: white;
$baseColor4: #fbc59d;
$baseColor5: rgba(0, 160, 227, 0.7);
$baseColor6: #d7e9ff;
$baseColor7: #ee7115;
$baseColor8: #489cdc;
$baseColor9: #00a1e4;
$baseColor10: #004b6a;
$baseColor11: #0f0c09;

$btnBgColor1: $baseColor1;
$btnColor1: $baseColor3;
$btnBgColor2: $baseColor2;
$btnColor2: $baseColor3;
$btnBgColor3: $baseColor4;
$btnColor3: $baseColor3;
$btnColor4: $baseColor1;
$btnBgColor4: $baseColor3;
$textWrapColor: $baseColor1;
$labelsAboutMeColor: $baseColor1;
$tagBgColor: $baseColor5;
$tagColor: $baseColor3;
$mainBgBaseColor: transparent;
$mainBgLineColor: $baseColor6;
$fieldHilightColor: $baseColor7;

$headerMsgCountColor: $baseColor3;
$headerRefColor: $baseColor3;
$headerRefHoverColor: $baseColor4;
$likes_countColor: $baseColor4;

$popupTitleColor: $baseColor3;
$popupTitleBgColor: $baseColor2;

$fieldBgColor: $baseColor8;
$fieldBoxShadowColor: color.change($fieldBgColor, $alpha: 0.73); // rgba(85, 68, 85, 0.5)
$fieldBorderColor: $baseColor5;
$checkboxCheckedColor: $baseColor4;
$checkboxBgColor: $fieldBgColor;
$checkboxBorderColor: $checkboxBgColor;
$optionBtnActiveBgColor: color.adjust($baseColor1, $lightness: 33%);

$contentOrderVipTitleColor: $baseColor9;
$contentOrderVipItemHeadColor: $baseColor9;
$contentOrderVipItemTextColor: $baseColor9;
$contentOrderVipOptionDescrColor: $baseColor4;
$contentOrderPriceColor: $orangeTextColor;

$warningBalanceBgColor: $baseColor10;
$warningBalanceCoinColor: $baseColor3;
$warningBalanceCommentColor: $baseColor3;

$messengerCommentColor: $baseColor3;
$dialogNameColor: $baseColor11;
$dialogTxtInputBorderColor: $baseColor7;
// $fieldBoxShadowColor: color.change($fieldBgColor, $lightness: 30%, $alpha: 0.5); // rgba(85, 68, 85, 0.5)
