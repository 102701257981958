.inboxMessages {

  &-root {
    margin: 40vh 0 0 0;
    width: 45%;
  }

  &-main {
    padding: 3vw 3vw 0;
  }

  &-inboxMsgItem {
    cursor: pointer;
    background: none;
    border: none;
    margin: 2vw 0;
  }

  &-content {
    padding: 0.1vw;
    width: 90%;
    background: linear-gradient(90deg, rgba(80,0,198,1) 0%, rgba(248,0,148,1) 100%);
    &:before {
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px;
    }
  }

  &-senderAvatar {
    width: 40%;
    height: 15vw;
    border-radius: 10px;
  }

  &-senderInfo {
    margin: 0 .3vw;
  }

  &-senderCity {
    font-size: 3vw;
  }

  &-senderName, &-senderSecondName, &-senderSurname {
    font-size: 4vw;
    width: 100%;
    word-break: break-all;
  }

  &-senderAge {
    font-size: 3vw;
  }

  &-wrapperInfo {
    margin-left: 2vw;
  }



}