@media(max-width: $medium-media) {
.activateVipScreen2 {

  &-root {
    max-width: 100%;
  }

  &-columns {
    padding-top: 4.2vw;
    width: 100%;
  }

  &-column {
    width: 45%;
  }

  &-under {
    margin-bottom: 24px;
  }

  &-image {
    width: 156px;
  }

  &-day {
    font-size: 15vw;
  }

  &-month {
    font-size: 6.2vw;
  }

  &-icon {
    width: 60%;
    margin-top: 1.05vw;
  }

  &-buy {
    width: 84%;
    font-size: 3.1vw;
    line-height: 1.2;
    padding-bottom: 4px;
  }

  &-price {
    font-size: 4.5vw;
    margin-bottom: 1.05vw;
  }

  &-title {
    // background: linear-gradient(90deg, rgba(151,87,150,1) 0%, rgba(254,87,205,1) 50%, rgba(151,87,150,1) 100%);
    background: $popupTitleBgColor;
    width: 95%;
    height: 5vw;
    margin: 6px auto;
  }

  &-close {
    background: none;
    border: none;
    &:hover {
      .activateVipScreen2-closeIcon {
        display: none;
      }

      .activateVipScreen2-closeIconHov {
        display: block;
      }
    }
  }

  &-closeIconHov {
    display: none;
  }

  &-closeIcon, &-closeIconHov {
    width: 4vw;
    height: 4vw;
  }

  &-titleText {
    font-size: 3vw;
    width: 90%;
    margin-left: 32px;
  }
  &-content {
    // background-image: url(../img/profileInTopBackgrMobile.jpg);
    // background-position: center;
    width: 97%;
  }

  &-main {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 1vw 0 16vw;
  }

  &-footer {
    margin-top: 3vw;
    margin-bottom: 3vw;
    width: 100%;
    order: 4;
  }

  &-activate, &-help {
    font-size: 3.2vw;
  }

  &-activate {
    // border: 3px solid #dbc758;
    width: 61%;
    &:hover {
      color: $black;
      background: #7dbae4;
    }
  }

  &-help {
    width: 52.9%;
  }

  &-bottom {
    width: 100%;
    margin-top: 4vw;
  }

}
}

@media(max-width: $small-media) {
  .activateVipScreen2 {

    &-title {
      height: 8vw;
    }

    &-titleText {
      font-size: 5vw;
    }

    &-closeIcon, &-closeIconHov {
      width: 5vw;
      height: 5vw;
    }


  }
}