.myProfileMain {
  // background-image: url(../img/bg.svg);
  // background-color: $mainBgBaseColor; /* Цвет фона */
  // background-image: linear-gradient(
  //   $mainBgBaseColor 20.6vw, 
  //   $mainBgLineColor 20.6vw,
  //   $mainBgLineColor 29.65vw,
  //   $mainBgBaseColor 29.65vw,
  // );
  // background-image: url('data:image/svg+xml,<svg width="1920" height="1072" xmlns="http://www.w3.org/2000/svg"><rect id="svg_1" height="174" width="1920" y="322" x="0" fill="#d7e9ff"/></svg>');
  // background-image: url(../img/bg.svg);
  height: 65vw;
  margin-top: -3.8vw;
  background-size: cover;
  z-index: 0;
  position: relative;
  padding-top: 3.8vw;

  &-content {
    padding-top: 10.7vw;
  }

  &-buttonTop {
    position: absolute;
    top: -1.4vw;
    font-size: .8vw;
    z-index: 1;
    padding: .49vw 1.7vw;

    /* для текста кнопки "хочу сюда" */
    font-size: 1.3vw;
    padding: 0.05vw 1.35vw;
    // font-style: italic;

    top: -0.8vw;
    z-index: 1;
    font-size: 0.9vw;
    padding: 0.2vw 1.3vw;
    min-width: 7.9vw; 
  }

  &-buttonWrapper {
    position: relative;
  }
  &-profileInTop {
    @extend %baseBoxShadow;
    width: 40.3%;
    width: 41%;
    margin-left: 3.6vw;
    background: #fff;
    position: relative;
    border-radius: 15px;
    padding: 24px 0;
    margin-top: 0.2vw;
    // box-shadow: 0 0 5px;
    &:before {
      content: "";
      position: absolute;
      top: -7px;
      bottom: -7px;
      left: -7px;
      right: -7px;
      // background: linear-gradient(0deg, rgba(157, 100, 24, 1) 0%, rgba(243, 236, 114, 1) 46%, rgba(220, 176, 94, 1) 100%);
      border-radius: 15px;
      z-index: -1;
    }
  }

  &-carousel {

    .myProfileMain-item img {
      height: 13.1vw;
    }

    .myProfileMain-item {
      .myProfileMain-coronaInTop {
          height: auto;
          position: absolute;
          width: 25%;
      }
    }
  }

  &-inTopPhotoWrapper {
    margin: 0.5vw auto;
    width: 80%;
    width: 77%;
    border: 5px solid $orangeBackground;
    background-color: $orangeBackground;
    background-color: #dbc758;
    border: $topCountryAvatarBorderThin solid $orangeBackground;
    // background-color: transparent;
    border-radius: 10px;
    position: relative;


  }

  &-shortInfoBar {
    background: rgba(0,0,0,.5);
    color: #fff;
    position: absolute;
    width: 100%;
    bottom: 0.4vw;
    padding: 0.2vw 0 0.2vw 1.17vw;
  }

  &-nameBar {
    font-size: 1.1vw;
    overflow-wrap: break-word;
  }

  &-yearsBar {
    font-size: 1.1vw;
  }

  &-smallLetter {
    font-size: 0.7vw;
  }

  &-cityBar {
    overflow-wrap: break-word;
  }

  &-inTopPhoto {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    &--no-photo {
      object-fit: none;
    }
  }

  &-coronaInTop {
    position: absolute;
    width: 22%;
    margin: 0.3vw 0.3vw;
  }

  &-inbox {
    font-size: 0.8vw;
  }

  &-buttonLoupe {
    width: 38%;
    height: 2.2vw;
    position: relative;
    border: 4px solid #dbc758;
    border-radius: 4px;
    color: #f48400;
    &:hover {
      background: #7dbae4;
    }
  }
  &-buttonFind{
    .myProfileMain-menuIcons {
      height: 3vw;
    }
  }

  &-msgWrapper {
    width: 56%;
  }

  &-senderAge {
    font-size: 0.9vw;
  }

  &-topLoupe {
    position: absolute;
    top: -8px;
    right: 16px;
    width: 60%;
  }

  &-viewOther {
    padding-left: 1.6vw;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &-viewTop {
    width: 54%;
  }

  &-senderAvatar {
    width: 31%;
    border-radius: 10px;
  }

  &-inboxMsgItem  {
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
    width: 100%;
    margin-top: .8vw;
    padding-left: 3%;
  }

  &-senderInfo {
    margin: 0 .3vw;
    width: 67%;
  }

  &-senderName, &-senderSecondName, &-senderSurname {
    font-size: .86vw;
    overflow-wrap: break-word;
    width: 100%;
  }

  &-senderCity {
    font-size: .8vw;
  }

  &-msgCount {
    font-size: 1.2vw;
  }

  &-msgIconUnread {
    background: #22a428;
    width: 38%;
    border-radius: 4px;
  }

  &-msgIconRead {
    width: 38%;
    border-radius: 4px;
  }

  &-myProfilePhotos {
    margin-left: 2.2vw;
    margin-left: 2.3vw;
    width: 43.98%;
  }

  &-mainAvatar {
    position: relative;
    // width: 16.2vw;
  }

  &-galleryImageLikeWrapper {
    position: relative;
    width: 48pt;
    z-index: 1; 
  }

  &-avatar {
    width: 100%;
    border-radius: 10px;
  }

  &-coronaOnAvatar {
    position: absolute;
    left: 0.4vw;
    bottom: 0.4vw;
    width: 35%;
  }

  &-photoWrapper,
  &-avatarWrapper {
    @extend %baseBoxShadow;
    background: $white;
    width: 30.8%;
    height: 5.8vw;
    margin: .5vw 0;
    position: relative;

    border-radius: 10px;
    // border: 3px solid #e8da97;
    border: 3px solid transparent;
    // box-shadow: 0 0.1vw 0.5vw rgba(#000000, 0.3);
    &:hover {
      .myProfileMain-photoHover {
        display: block;
      }
    }
  }
  &-avatarWrapper {
    width: 100%;
    height: inherit;
    margin: inherit;
    border-radius: 10px;
    // border: 4px solid #e8da97;
    border: none;
    position: inherit;
    &:hover {
      .myProfileMain-avatarHover {
        display: block;
      }
    }
  }

  &-photoMenuWrapper {
    position: absolute;
    right: .4vw;
    top: .4vw;
    width: 15%;
    background: none;
    border: none;
    cursor: pointer;
  }

  &-photoMenuWrapper {
    opacity: 0;
  }

  &-mainAvatar {
    &:hover{
      .myProfileMain-photoMenuWrapper {
        opacity: 1;
      }
    }
  }

  &-photoMenu {
    width: 100%;
  }

  &-photoIcon,
  &-noPhotoIcon {
    object-fit: cover;
    height: 101%;
    width: 101%;
    // border-radius: 10px;
    border-radius: 8px;
    // border: 4px solid #e8da97;
  }

  &-noPhotoIcon {
    opacity: 0.1;
    object-fit: contain;
  }

  &-myPhotoButton {
    width: 56%;
    margin: 0 auto;
    font-size: 1.1vw;
  }

  &-pinkButton {
    position: relative;
    border: 3px solid #dacb56;
    border-radius: 2px;
    font-size: 1vw;
    padding: .1vw 2.7vw;
    &:before {
      content: "";
      position: absolute;
      top: -9px;
      bottom: -9px;
      left: -9px;
      right: -9px;
      background: linear-gradient(0deg, rgba(157, 100, 24, 1) 0%, rgba(243, 236, 114, 1) 46%, rgba(220, 176, 94, 1) 100%);
      border-radius: 8px;
      z-index: -1;
    }
    &:hover {
      background: #7dbae4;
      color: $black;
    }
  }

  &-aboutMySelf {
    margin-left: 2vw;
    width: 49%;
  }


  &-findButton {
    margin-top: .8vw;
    margin-bottom: .8vw;
  }

  &-loupe {
    position: absolute;
    top: -0.9vw;
    right: -2.1vw;
    width: 39%;
  }

  &-infoItem {
    margin: 0.6vw 0;
    font-size: 1.75em;
    &::after {
      // position: absolute;
      // right: -.7em;
      // display: inline-block;
      // margin-left: .255em;
      // vertical-align: .255em;
      // content: "";
      // border-top: .3em solid orange;
      // border-right: .3em solid transparent;
      // border-bottom: 0;
      // border-left: .3em solid transparent;]
      display: inline-block;
      margin-left: .255em;
      vertical-align: .255em;
      content: "";
      border-top: .3em solid transparent;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
    }
  }

  &-infoItem:hover.editable,
  &-infoItem:hover.editable {
    &::after {
      // position: absolute;
      // right: -.7em;
      // display: inline-block;
      // margin-left: .255em;
      // vertical-align: .255em;
      // content: "";
      // border-top: .3em solid orange;
      // border-right: .3em solid transparent;
      // border-bottom: 0;
      // border-left: .3em solid transparent;]
      // display: inline-block;
      // margin-left: .255em;
      // vertical-align: .255em;
      // content: "";
      // border-top: .3em solid $fieldHilightColor;
      // border-right: .3em solid transparent;
      // border-bottom: 0;
      // border-left: .3em solid transparent;

      border-top-color: $fieldHilightColor;
    }
  }

  &-fieldValueWrapper {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }

  &-purpleItem,
  &-whiteItem {
    // padding: 2px 4px;
  }

  &-infoMobile &-information &-infoItem &-purpleItem,
  &-infoMobile &-information &-infoItem &-whiteItem,
  // TODO: добавить постоянную обводку для всех полей на мобильном(см. макет) 
  &-infoItem:hover.editable &-purpleItem,
  &-infoItem:hover.editable &-whiteItem {
    // outline: 2px solid $fieldHilightColor;
    // border-radius: 10px;
    // max-width: 89%;

    outline: $fieldHilightColor solid 0.2vw;
    outline-offset: 0.1vw;
    border-radius: 0.4vw;
  }

  &-infoMobile &-information &-infoItem &-purpleItem,
  &-infoMobile &-information &-infoItem &-whiteItem {
    // outline: 2px solid $fieldHilightColor;
    // border-radius: 10px;
    // max-width: 89%;
    min-width: 66%;
    min-width: 32vw;
    outline-width: 0.5vw;
    outline-offset: 0.3vw;
    border-radius: 1vw;
  }

  &-icon {
    width: 8%;
    margin-right: 0.5vw;
  }

  &-aboutMySelfLeftColumn {
    // width: 38%;
    // display: inline-block;
    // max-width: 38%;
    width: 30%;
  }

  &-menuAndAboutMyself {
    margin-left: 1.6vw;
    max-width: 59%;
  }

  &-conciseInfo {
    // margin-left: 0.2vw;
    // margin-bottom: 1.6vw;

    margin-left: 2vw;
    // margin-bottom: 1.6vw;
    padding-top: .5vw;
    height: 8.5vw;
    font-size: 0.5vw;
  }

  &-conciseInfo, &-information {
    // margin-left: 0.2vw;
    // margin-bottom: 1.6vw;

    margin-left: 2.4vw;
  }

  &-name {
    // font-size: 2vw;
    font-size: 4em;
  }

  &-infoItems {
    // margin-top: 1.3vw;
    // font-size: 1.2vw;
    // font-size: 2em;
  }
  &-icon {
    // width: 13%;
    width: 1.8em;
    width: 1.8vw;
  }

  &-purpleItem {
    font-size: 1vw;
  }

  &-topItem {
    position: relative;
    // padding: .3vw 0 0;
    // border-radius: 5px;
    // width: 30%;
    // width: 5.8vw;
    // height: 6.6vw;
    // height: 3.6vw;
    // margin-right: 1vw;
    // background: rgba(255,255,255,.7);
    
    // padding-top: 1.2vw;
    // width: 5.9vw;
    position: relative;
    padding: 0;
    margin: 1.3vw 1.1vw 0 1.5vw;
    background: 0 0;
    border: none;
  }

  // &-topItem:first-child {
  //   margin: 1.3vw 1.5vw;
  // }

  &-redDot, &-greenDot, &-grayDot, &-inlineDot {
    border-radius: 50%;
    width: 0.8vw;
    height: 0.8vw;
    position: absolute;
    top: .4vw;
    right: .4vw;
  }

  &-inlineDot {
    position: relative;
    display: inline-block;
    top: .1vw;
  }

  &-redDot {
    background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(195,0,0,1) 100%);
  }

  &-greenDot {
    background: linear-gradient(90deg, rgba(69,183,89,1) 0%, rgba(70,145,85,1) 100%);
  }

  &-grayDot {
    background: linear-gradient(90deg, rgb(185, 187, 185) 0%, rgb(90, 90, 90) 100%);
  }

  &-vipActive {
    margin-bottom: 1.5vw;
  }

  &--order-btn-text-wrapper {
    padding: .25vw 0;
    // display: inline-block;
    line-height: normal;
  }

  &-buttonsBar {
    // padding-top: 0.35vw;
    // margin-bottom: 1vw;
    min-height: 6vw;
    margin-left: 1vw;
  }


  
  &-buttonsBlock{
    margin-top: .8vw;
  }

  &-menuIcons {
    // width: 50%;
    // width: 3vw;
    height: 2vmax;

  }

  &-menuText {
    font-size: 0.8vw;
  }

  &-boldText {
    font-weight: 700;
    font-size: 0.9vw;
  }

  &-menuButton {
    background: $white;
    width: 100%;
    font-size: 0.9vw;
  }

  &-youProfileInTopText {
    // margin-top: -1vw;
    // line-height: 0.9vw;
    white-space: nowrap;
  }

  &-buttonsBlock {
    padding: 1.2vw 0.15vw;
    padding: 1.2vw 0.15vw;
    border-radius: 5px;
    border: 1px solid rgba(255,255,255, 0.0);
    // width: 7.6vw;
    // height: 6.3vw;
    margin: 0;
    width: 100%;
  }

  &-simpleButton {
    font-size: 0.9vw;
    background: none;
    border: none;
    text-decoration: underline;
    color: $white;
  }

  &-purpleWrap {
    background: rgba(112, 43, 136, 0.6);
    padding: 0.2vw 0.2vw 0.2vw 0.2vw;
    border-radius: 5px;
  }

  &-textWrap {
    background: none;
    // padding: 0.2vw 0.2vw 0.2vw 0.2vw;
    padding: 0vw .2vw;
    // border-radius: 5px;
    border-left: $textWrapColor solid 0.2vw;
  }

  &-tag {
    display: inline-block;
    color: $tagColor;
    // background: rgba(112, 43, 136, 0.6);
    background: $tagBgColor;
    padding: 0.1vw 0.7vw;
    margin: 0.1vw;
    border-radius: 5px;
  }

  &-findInformation {
    width: 48%;
  }

  &-aboutMe {
    margin-top: 0.7vw;
    // >*:first-child{
    //   mar
    // }
    .field-label {
      color: $labelsAboutMeColor;
    }
  }

  &-textInBubble {
    font-size: 1vw;
  }

  &-bubble {
    font-size: 0.9vw;
  }

  &-topAndPhotosMobile {
    width: 36.7%;
    display: flex;
    flex-direction: row;
  }

  &-photoHover,
  &-avatarHover {
    // font-size: 6vw;
    position: absolute;
    // top: -2.5vw;
    // top: 3.5vw;
    bottom: 0;
    display: none;
  }
  &-avatarHover {
    top: 4.5vw;
    left: 39%;
  }
  &-dropdownTitle {
    font-size: 20px;
  }

  &-list,
  &-list-photo-menu {
    position: absolute;
    inset: unset !important;
    margin: 0px;
    transform: unset !important;
    left: 48px !important;
    top: 45px !important;
    overflow-y: auto; 
    max-height: 80vh;
    min-width: 20vw;
  }
  &-list-photo-menu {
    left: calc(-5% + 5px) !important;
    top: unset !important;
    width: 110%;
    min-width: 16vw;
  }
  &-photoMenuButton {
    width: 68%;
    margin: 0 auto;
    font-size: 1.1vw;
    padding: .3vw;
  }
  &-dropdownNameField {
    width: 300px;
  }
  &-dropdownAgeField {
    width: 70px;
    text-align: center;
  }
  &-small {
    font-size: 12px;
    // width: 228px;
    margin: 4px 0;
  }
  &-dialogs-list-button-wrapper {
    width: 100%;
    padding: 4% 11%;
  }

  &-aboutMeModule {
    min-height: 9.3vw;
    &:after {
      background: green;
      background: #d7e9ff;
      content: "";
      display: block;
      width: 100vw;
      left: 0;
      height: revert;
      position: absolute;
      z-index: -4;
      min-height: 9.3vw;
    }
  }

  &-someInfoAboutMe {
    margin-top: 1.4vw;
    font-size: 1em;
  }
}

@media(max-width: $extraLarge-media) {
  .myProfileMain {
    &-name {
      // font-size: 20px;
      font-size: 30px;
      // height: 60px;
    }

    &-infoItems {
      font-size: 12px;
    }

    &-infoItem {
      margin: .3vw 0;
      font-size: 1em;
    }

    &-profileInTop {
      border-radius: 12px;

      &:before {
        top: -4px;
        bottom: -4px;
        left: -4px;
        right: -4px;
      }
    }

    &-pinkButton:before {
      top: -6px;
      bottom: -6px;
      left: -6px;
      right: -6px;
      border-radius: 5px;
    }

    &-textInBubble {
      font-size: 12px;
      font-size: 12px;
    }

    &-purpleItem {
      font-size: 12px;
    }

  }
}

@media(max-width: $large-media) {
  .myProfileMain {

    // background-image: linear-gradient(
    //   $mainBgBaseColor 20.6vw, 
    //   $mainBgLineColor 20.6vw,
    //   $mainBgLineColor 32.65vw,
    //   $mainBgBaseColor 32.65vw,
    // );

    &-aboutMeModule {
      min-height: 13vw;
      &:after {
        // remove backbround blue line
        min-height: 13vw; 
      }
    }

    // &-conciseInfo {
    //   // background: rgba(112,43,136,.5);
    //   padding: 0.1vw 1vw;
    //   border-radius: 5px;
    // }

    &-content {
      justify-content: center;
    }

    &-profileInTop {
      width: 44.8%;
      margin-left: 1vw;
    }

    &-inTopPhotoWrapper {
      border: 2px solid #ff9306;
      width: 61%;
    }

    &-buttonTop {
      font-size: 1vw;
    }

    &-inboxMsgItem {
      width: 90%;
    }

    &-messageText {
      font-size: 1.2vw;
    }

    &-yearsBar, &-cityBar, &-nameBar {
      font-size: 1.1vw;
    }

    &-shortInfoBar {
      border-radius: 5px;
    }

    &-msgIconRead, &-msgIconUnread {
      width: 32%;
      border-radius: 2px;
    }

    &-aboutMySelf {
      width: 52%;
    }

    // &-aboutMe {
    //   margin: 1vw 0;
    //   background: rgba(112,43,136,.5);
    //   padding: 1.1vw 1vw;
    //   border-radius: 5px;
    // }

    &-purpleWrap {
      background: none;
      padding: .2vw;
    }

    &-textWrap {
      padding: .2vw;
    }

    &-icon {
      margin-right: 10px;
    }

    &-myProfilePhotos {
      margin-left: 2.2vw;
      width: 63%;
    }

    &-photoWrapper {
      height: 7.8vw;
    }

    &-myPhotoButton {
      width: 68%;
      font-size: 1.5vw;
      background: $white;
    }

    &-name {
      font-size: 2vw;
      font-size: 2em;
      // margin-bottom: 2vw;
    }

    &-vipActive {
      margin-bottom: 2.5vw;
    }

    &-whiteItem {
      font-size: 1.6vw;
    }

    &-menuAndAboutMe {
     flex-wrap: wrap;
     justify-content: center;
    }

    &-findButton {
      margin: 1.8vw;
      width: 100%;
    }

    &-pinkButton {
      position: relative;
      border: 3px solid #dacb56;
      border-radius: 2px;
      font-size: 1.3vw;
      padding: .1vw 2.7vw;
      width: 60%;
    }

    &-loupe {
      top: -1.9vw;
      right: -3.5vw;
      width: 47%;
    }

    &-buttonsBlock {
      flex-direction: row;
      width: 94%;
      height: 3vw;
    }

    &-menuText {
      font-size: 1vw;
    }

    &-textInBubble {
      font-size: 1.2vw;
    }

    &-boldText {
      font-size: 1.1vw;
    }

    &-topItem {
      width: 28%;
      // height: 9vw;
      // margin: 0 auto 5px;
      margin: 0;
      background: rgba(255,255,255,.0);
    }

    &-menuButton {
      font-size: 1.2vw;
    }

    &-simpleButton {
      font-size: 1.2vw;
    }

    &-bubble {
      font-size: 1.3vw;
    }

    &-topAndPhotosMobile {
      width: 43.7%;
    }

    &-senderName, &-senderSecondName, &-senderSurname {
      font-size: 1.2vw;
    }

    &-topLoupe {
      top: -0.9vw;
      right: 0.5vw;
      width: 60%;
    }

    &-buttonLoupe {
      height: 3.2vw;
    }

    &-viewTop {
      font-size: 1vw;
    }

    &-profileInTop {
      align-self: flex-start;
    }

    &-photoHover {
      // top: -1.6vw;
      bottom: 0;
    }
    &-avatarHover {
      top: 35%;
      left: 39%;
    }
    &-photoWrapper,
    &-avatarWrapper {
      width: 30.8%;
      height: 5.8vw;
      margin: .5vw 0;
    }
    &-avatarWrapper {
      width: 100%;
      height: inherit;
      margin: inherit;
    }
  }
}

@media(max-width: $medium-media) {
  .myProfileMain {
    // background-image: url(../img/myProfileMain-mobile.png);
    height: auto;
    // margin-top: -0.8vw;
    background-size: unset;
    z-index: 0;
    position: relative;

    &-content {
      margin-top: -1vw;
      padding-top: 0;
    }

    &-avatar {
      // height: 34.7vw;
      height: 32.3vw;
      object-fit: cover;
    }

    // &-infoItem {
    //   font-size: 1em;
    // }

    &-infoItems {
      & img {
        width: 15%;
        width: 6vw;
      }
    }

    &-conciseInfo &-infoItems {
      & img {
        width: 6vw;
      }
    }

    &-bubble {
      font-size: 1em;
    }
    &-textInBubble {
      font-size: 1em;
    }
    &-textWrap {
      padding: 0.6vw;
      border-width: 0.6vw;
    }

    // &-infoMobile &-name{
    //   font-size: 1vw;
    // }

    &-topAndPhotosMobile {
      justify-content: space-between;
      width: 33%;
      flex-direction: column-reverse;
    }

    &-aboutMySelf {
      width: 66%;
      margin-left: 0;
    }

    &-profileInTop {
      padding: 0;
      background: none;
      flex-direction: column-reverse;
      width: 95.8%;
      &:before {
        display: none;
      }
    }

    &-carousel {
      .myProfileMain-item img {
        height: 36vw;
      }
    }

    &-messages {
      background: none;
      border: none;
      &:focus {
        outline: none;
      }
      &:hover {
        .myProfileMain-pinkMessageIcon {
          transform: scale(1.2);
        }
      }
    }

    &-pinkMessageIcon {
      width: 18vw;
    }

    &-messQuantity {
      width: 100%;
      font-size: 5vw;
    }

    &-inTopPhoto {
      height: 35.2vw;
    }

    &-inTopPhotoWrapper {
      width: 100%;
      border: 4px solid #ff9306;
    }

    &-cityBar, &-nameBar, &-yearsBar {
      font-size: 3.4vw;
      letter-spacing: -1px;
    }

    &-msgIconRead, &-msgIconUnread {
      width: 50%;
    }

    &-smallLetter {
      font-size: 3vw;
    }

    &-inbox {
      align-items: start;
      width: 100%;
      padding-bottom: 6vw;
    }

    &-inboxMsgItem {
      flex-direction: column;
      align-items: start;
      width: 100%;
      margin-bottom: 2vw;
    }

    &-coronaInTop {
      display: none;
      position: absolute;
      bottom: 11vw;
      width: 31%;
      margin: .3vw;
    }

    &-msgCount {
      margin-bottom: 5vw;
      font-size: 6.8vw;
    }

    &-senderInfo {
      width: 100%;
    }

    &-senderAvatar {
      width: 50%;
    }

    &-senderName {
      letter-spacing: -1px;
      font-size: 4.2vw;
    }

    &-senderAge {
      font-size: 4.2vw;
      margin-right: 1vw;
    }

    &-senderCity {
      letter-spacing: -1px;
      font-size: 3.9vw;
      margin: 0;
    }

    &-myProfilePhotos {
      // margin: 1vw 1vw 0 0;
      // width: 86%;
      // margin-top: -13.5vw;
      // align-items: center;
      
      margin: 2.8vw 0 0 4.3vw;
      width: 90%;
      object-fit: cover;
    }

    &-purpleItem, &-whiteItem {
      font-size: 2em;
    }

    // &-whiteItem {
    //   color: #712f9e;
    // }

    &-pinkButtonAboutMe {
      position: relative;
      border: 3px solid #dacb56;
      border-radius: 2px;
      font-size: 3vw;
      padding: 0.4vw 0;
      width: 95%;
      margin-top: -3vw;
      box-shadow: inset 0px 7px 15px -4px rgba(0,0,0,0.55);
      &:before {
        content: "";
        position: absolute;
        top: -9px;
        bottom: -9px;
        left: -9px;
        right: -9px;
        background: linear-gradient(0deg, rgba(157, 100, 24, 1) 0%, rgba(243, 236, 114, 1) 46%, rgba(220, 176, 94, 1) 100%);
        border-radius: 6px;
        z-index: -1;
      }
      &:hover {
        background: #7dbae4;
        color: $black;
      }
    }


    &-anotherPhoto {
      margin-top: 0.6vw;
    }

    &-mainAvatar {
      position: relative;
      // z-index: -1;
    }

    &-simpleButton {
      color: $black;
      font-size: 5vw;
    }

    &-galleryMobile &-anotherPhotos {
      // margin-top: 1vw;
      width: 81%;
    }

    &-buttonTop {
      bottom: 32.6vw;
      top: auto;
      padding: .9vw 5.6vw;
      border: 4px solid #fff;
      font-size: 2.7vw;
      z-index: 2;
    }

    &-aboutMeModule {
      // justify-content: center;
      height: auto;
      padding: 0 5vw;
      
      &:after {
        // remove backbround blue line
        display: none; 
      }
    }

    &-aboutMeModule &-conciseInfo {
      margin: 0;
    }

    &-information {
      padding: 4.1vw 0 0 4.9vw
    }

    &-information &-infoItems {
      font-size: 0.7em;
      padding: 3.1vw;
      padding: 0;
    }

    &-menuAndAboutMyself {
      // margin-left: 0;
      margin: -2vw 0 0 0;
      width: 100%;
      max-width: 100%;
    }

    &-menuAndAboutMe {
      flex-direction: row-reverse;
    }

    &-topItem {
      width: 32%;
      height: 16vw;
      background: none;
    }

    &-redDot, &-greenDot, &-grayDot {
      width: 3vw;
      height: 3vw;
    }

    &-statusDot {
      position: absolute;
      top: unset;
      right: unset;
      margin: 0.5vw -1vw;
    }

    &-statusStr {
      // padding-left: 3vw;
      padding: 3vw 3vw;
      margin-top: -3vw;
    }

    // &-arrowUp {
    //   & img {
    //     margin-top: -3.8vw;
    //   }
    // }

    &-menuIcons {
      // width: 50%;
      // width: 3vw;
      height: 9vw;
      width: 13vw;
      /* display: contents; */
      object-fit: contain;
  
    }
    &-buttonFind &-menuIcons {
      height: 13.5vw;
    }

    &-menuButton {
      background: red;
      width: 65%;
      font-size: 2vw;
      font-weight: 700;
      color: $white;
    }

    &--order-btn-text-wrapper {
      padding: 0;
      height: 10vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      // display: inline-block;
    }

    &-menuText {
      // font-size: 2.3vw;
      font-size: 3.2vw;
      white-space: normal;
    }

    &-boldText {
      font-size: 2.7vw;
    }

    &-information {
      position: relative;
      background: #ffffff;
      border: 2px solid rgba(133, 133, 133, 0.4);
      border-radius: 10px;
      z-index: 0;
    }

    &-pinkButton {
      position: absolute;
      right: 8vw;
      top: 3vw;
      width: 45%;
      font-size: 3.1vw;
      box-shadow: inset 0 7px 15px -4px rgba(0,0,0,.55);
      &:before {
        content: "";
        position: absolute;
        top: -9px;
        bottom: -9px;
        left: -9px;
        right: -9px;
        background: linear-gradient(0deg, rgba(157, 100, 24, 1) 0%, rgba(243, 236, 114, 1) 46%, rgba(220, 176, 94, 1) 100%);
        border-radius: 8px;
        z-index: -1;
      }
    }

    &-icon {
      width: 15%;
      margin-right: 5px;
      margin-right: 1.4vw;
    }

    &-loupe {
      top: -3.9vw;
      right: -8.5vw;
      width: 46%;
    }

    &-findInformation {
      width: 100%;
      padding-left: 1.1vw;
      letter-spacing: -1px;
    }

    &-purpleItem, &-whiteItem {
      // font-size: 4.6vw;
      // margin: 2vw 0;
      font-size: 1em;
    }

    &-conciseInfo &-purpleItem, 
    &-conciseInfo &-whiteItem {
      // font-size: 4.6vw;
      margin: 0 !important;
      font-size: 1.3em !important;
    }

    &-goldBorder {
      border: 2px solid #dbc758;
      border-radius: 10px;
      padding: 0 4px;
    }

    &-photoWrapper {
      // height: 9.8vw;
      height: 18.5vw;
    }

    &-galleryMobile {
      padding-left: 3vw;
    }

    &-photoHover {
      // top: -0.7vw;
      bottom: 0;
    }
    &-avatarHover {
      top: 3vw;
    }
    &-list,
    &-list-photo-menu {
      padding: 1vw 2vw;
      font-size: 3.4vw;
    }
    &-dropdownNameField {
      width: 300px;
    }
    &-dropdownAgeField {
      width: 12vw;
    }
    &-small {
      font-size: 2vw;
      width: 100%;
      margin: 0.5vw 0;
    }
    &-buttonsBlock {
      width: 100%;
      margin-top: 0;
      padding-top: .3vw;
      height: 10vw;
    }
    &-buttonsBar--mobile {
      // padding-top: 0.35vw;
      // margin-bottom: 1vw;
      // min-height: 8vw;
      padding: 0 4.7vw;
      width: 100%;
      button {
        // padding: 0.3vw 4.6vw;
        // font-size: 3vw;
        padding: 0.3vw 4.6vw;
        font-size: 3.6vw;
        /* line-height: 1.8vw; */
        min-width: 27vw;
      }
    }
    &-infoMobile &-conciseInfo &-infoItems {
      // font-size: 0.7em;
      padding: 2.6vw 0 0 0;
    }
    &-infoMobile &-conciseInfo {
      // font-size: 0.7em;
      height: auto;
      font-size: 3.5vw;
    }
    &-infoMobile &-information {
      border: none;
      background: none;
      font-size: 1.4em;
      margin: 0
    }
    &-infoMobile &-information &-infoItems {
      max-width: 49vw;
    }
    &-infoMobile &-information &-infoItem {
      margin-bottom: 2vw;
    }

    &-infoMobile &-name {
      max-width: 50%;
    }
  }
}

@media(max-width: $small-media) {
  .myProfileMain {
    // &-purpleItem, &-whiteItem {
    //   font-size: 4.6vw;
    // }

    &-pinkButtonAboutMe:before {
      content: "";
      position: absolute;
      top: -5px;
      bottom: -5px;
      left: -5px;
      right: -5px;
      background: linear-gradient(0deg,#9d6418 0,#f3ec72 46%,#dcb05e 100%);
      border-radius: 6px;
      z-index: -1;
    }

    &-pinkButton:before {
      top: -5px;
      bottom: -5px;
      left: -5px;
      right: -5px;
      border-radius: 6px;
    }

    &-inbox {
      padding-bottom: 3vw;
    }

    &-buttonTop {
      bottom: 35vw;
      top: auto;
      padding: .9vw 5vw;
      border: 2px solid #fff;
      font-size: 2.5vw;
    }

    &-coronaInTop {
      bottom: 17vw;
    }
  }
}
